import React, { useState, useEffect } from "react";
import { Col, Row, Input, Label, Form, FormFeedback, Card } from "reactstrap";
import { Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";

import { Image } from 'primereact/image';
import { deleteBanner, getCourse } from "../../store/course/reducer";
        

const Step1 = ({ setFieldValue, data }) => {

  console.log('hello data', data)

  const dispatch = useDispatch();
  const { loading, response } = useSelector(state => ({
    loading: state.course.isDeleting,
    response: state.course.deleteResponse,
}))

  const [selectedFile, setselectedFile] = useState([]);

  useEffect(() => {
    dispatch(getCourse(data._id))
  }, [response])

  function handleAcceptedFile(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFile(files);
  }

  // remove attachment
  function removeFile(f) {
    const newFiles = selectedFile.filter((file) => file !== f);
    setselectedFile(newFiles);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const icon = (<i className="pi pi-search"></i>)

  const handleDelete = (id) => {
    dispatch(deleteBanner(id))
    dispatch(getCourse(id))
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={5}>
          {/* <h2 className="mt-3">
            Let’s create your course
          </h2> */}
          <p className="mb-3 text-muted font-size-18">
            Provide your course title and description
          </p>
          <p className="mb-4 font-weight-bold">Step 1 of 4</p>


          <Field name="title">
            {({ field, form }) => (
              <div className="mb-4">
                <Label className="form-label">Course Title</Label>
                <Input
                  {...field}
                  name="title"
                  type="text"
                  placeholder="Enter your course title"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.title || ""}
                  invalid={
                    form.touched.title && form.errors.title
                      ? true
                      : false
                  }
                />
                <FormFeedback>{form.errors.title}</FormFeedback>
              </div>
            )}
          </Field>

          <Field name="description">
            {({ field, form }) => (
              <div className="mb-5">
                <Label className="form-label">Course Description </Label>
                <textarea
                  {...field}
                  name="description"
                  className="form-control"
                  rows="3"
                  placeholder="Enter your business description here"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.description || ""}
                />
                <FormFeedback>{form.errors.description}</FormFeedback>
              </div>
            )}
          </Field>

          <div>
            <Field name="banner">
              {({ field, form }) => (
                <div className="mb-5">
                  {data?.courseBanner !== "" ?
                      <div className="d-flex">
                        <Image src={form.values.banner} zoomSrc={form.values.banner} alt="Image" width="80" height="60" preview /> 
                        <div className="mx-3 mt-3 text-danger" onClick={() => handleDelete(form.values.id)}>
                          {loading? "isDeleting..." : "Remove"}
                        </div>
                      </div>
                    :
                     <div>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                        handleAcceptedFile(acceptedFiles);
                        setFieldValue('banner', acceptedFiles)
                      }}
                      accept="image/*"
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone mt-3">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-1">
                              <i className="display-5 text-muted bx bx-cloud-upload" />
                            </div>
                            <h5>Drop your banner, or click to browse.</h5>
                            <small className="text-muted fs-6 fw-light">
                              1600 x 1200 (4:3) recommended, up to 10MB each.
                            </small>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews my-4" id="file-previews">
                      {selectedFile.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  {f.type === "application/pdf" ? (
                                    <div className="avatar-sm">
                                      <div className="avatar-title rounded bg-light text-muted font-size-24">
                                        <i className="fas fa-file-pdf" />
                                      </div>
                                    </div>
                                  ) : (
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  )}
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col className="col-auto">
                                  <Link
                                    to="#"
                                    className="btn btn-link p-1"
                                    onClick={() => {
                                      removeFile(f);
                                    }}
                                  >
                                    <i className="dripicons-cross text-danger fs-5" />
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                  }  
                </div>
              )}
            </Field>  
          </div>
          
          <div className="d-flex justify-content-between">
            <Field name="hasQuiz">
              {({ field, form }) => (
                <div className="mb-0">
                  <Label className="form-label me-2">Course Has Quiz </Label>
                  <Input
                  {...field}
                  type="checkbox"
                  name="hasQuiz"
                  defaultChecked={form.values.hasQuiz}
                  onChange={form.handleChange}
                  />
                </div>
              )}
            </Field>

            <Field name="hasProject">
            {({ field, form }) => (
              <div className="mb-0">
                <Label className="form-label me-2">Course Has Project </Label>
                <Input
                {...field}
                type="checkbox"
                name="hasProject"
                defaultChecked={form.values.hasProject}
                onChange={form.handleChange}
                />
              </div>
            )}
          </Field>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Step1;
