import React, { useEffect } from "react";
import {
  Table,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";

function TableRow({ row, history }) {
  return (
    <tr>
      <td className="">
        {row.profileUrl !== null ? (
          <img
            src={row.profileUrl}
            alt=""
            className="rounded-circle avatar-sm me-4"
          />
        ) : (
          <Avatar
            className="me-4"
            maxInitials={2}
            size={30}
            round={true}
            name={row.firstName === undefined ? " " : row.firstName}
          />
        )}
        {row.name}
      </td>
      <td>{row.course}</td>
      <td>{row.completionPercentage}%</td>
      <td>
        <div
          className={`badge badge-soft-${
            row.completionPercentage === 100 ? "success" : "warning"
          } font-size-12`}
        >
          {row.completionPercentage === 100 ? "Completed" : "Pending"}
        </div>
      </td>

      <td>
        <div className="">
          <UncontrolledDropdown className="dropdown text-end">
            <DropdownToggle className="text-muted font-size-20" tag="a">
              <i className="bx bx-dots-horizontal-rounded"></i>
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() =>
                  history.push(`/submission/${row.studentId}/${row.courseId}`)
                }
              >
                View Submission
              </DropdownItem>
              <DropdownItem to="#">Issue Certificate</DropdownItem>
              <DropdownItem to="#">View Certificate</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </td>
    </tr>
  );
}

const EnrolledList = ({ enrols }) => {
  const history = useHistory();

  return (
    <Card>
       <Table className="align-left mb-0 ">
          <thead>
            <tr>
              <th className="p-3">Student Name</th>
              <th className="p-3">Course Name</th>
              <th className="p-3">Progress (%)</th>
              <th className="p-3">Status</th>
              <th className="p-3 text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {enrols?.map((row) => (
              <TableRow key={row.id} row={row} history={history} />
            ))}
          </tbody>
        </Table>
    </Card>
  );
};

export default EnrolledList;
