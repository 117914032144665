import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: [],
  isLoading: false,
  response: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    //Upload Picture
    uploadPicture: (state) => {
        state.isLoading = true
    },
    uploadPictureSuccess: (state, action) => {
      state.url = action.payload;
      state.isLoading = false
    },
    uploadPictureFailure: (state) => {
        state.isLoading = false
    },

    //Update Profile
    updateProfile: (state) => {
        state.isLoading = true
    },
    updateProfileSuccess: (state, action) => {
      state.response = action.payload;
      state.isLoading = false
    },
    updateProfileFailure: (state) => {
        state.isLoading = false
    },
    
  },
});

// Action creators are generated for each case reducer function
export const { updateProfile, updateProfileSuccess, updateProfileFailure, uploadPicture, uploadPictureSuccess, uploadPictureFailure } =
  profileSlice.actions;

export const profileSelector = (state) => state.profile;

export default profileSlice.reducer;
