import { call, put, takeEvery } from "redux-saga/effects";
import {
  getStats,
  getUser,
  refreshToken,
  signInUser,
} from "../../../api/requests/auths";
import {
  createRefreshTokenFailure,
  createRefreshTokenSuccess,
  getStatsFailure,
  getStatsSuccess,
  getUserFailure,
  getUserSuccess,
  loginFailure,
  loginSuccess,
} from "./reducer";
import { toast } from "react-toastify";

function* logInUser({ payload: user }) {
  try {
    const response = yield call(signInUser, user);
    localStorage.setItem("token", response.data.data.token);

    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    yield put(loginSuccess(response.data));
    window.location.href = "/dashboard";
  } catch (error) {
    toast.error(error.response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    yield put(loginFailure(error));
  }
}

//Get Logged In User
function* fetchUser() {
  try {
    const response = yield call(getUser);
    localStorage.setItem("user_id", response.data.data._id);

    yield put(getUserSuccess(response.data.data));
  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    yield put(getUserFailure(error));
  }
}

//Refresh Token

function* tokenRefresh({ payload: values }) {
  try {
    const response = yield call(refreshToken, values);

    localStorage.setItem("token", response.data.data.token);

    yield put(createRefreshTokenSuccess(response.data));

    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    yield put(createRefreshTokenFailure(error.response.data.message));
  }
}

//Get Logged In User
function* fetchStats() {
  try {
    const response = yield call(getStats);
    // localStorage.setItem("user_id", response.data.data._id)

    yield put(getStatsSuccess(response.data.data));
  } catch (error) {
    toast.error(error.response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    yield put(getStatsFailure(error));
  }
}

function* logInSaga() {
  yield takeEvery("login/createLogin", logInUser);
  yield takeEvery("login/getUser", fetchUser);
  yield takeEvery("login/getStats", fetchStats);
  yield takeEvery("login/createRefreshToken", tokenRefresh);
}

export default logInSaga;
