import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
// import { userCreatePassword, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useParams } from "react-router-dom";
import { PulseLoader } from 'react-spinners';

// import images
import logo from "../../assets/images/stoched_.png";
import CarouselPage from "../AuthenticationInner/CarouselPage";
// import loginbg from "../../assets/images/loginbg.png";

import { verifyEmail } from "../../store/auths/emailConfirmation/reducer";


// this is shown after success

const EmailConfirmation = (props) => {

  const { id } = useParams();

  function handleConfirm(){
    dispatch(verifyEmail(id))
  }

  const { loading } = useSelector(state => ({
    loading: state.emailConfirmation.isLoading,
  }))

  //meta title
  document.title = "Stoched | Email Confirmation";


  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="overlay">
        <Container fluid className="p-0">
          {/* <Row className="g-0"> */}
            {/* <Col lg={4} md={5} className="col-xxl-4"> */}
              <div className="d-flex p-sm-5 p-4">
                <div className="mx-auto col-10 col-xl-7">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-md-2">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <span className="logo-txt">Email Confirmation</span>
                      </Link>
                    </div>

                    <div className="auth-content mt-5">

                        <div className="">
                            <h2 className="mb-0 mt-5">
                                Confirm Your Email
                            </h2>
                            <p className="text-muted mt-3">
                                You have successfully verified your email on Stoched
                            </p>
                        </div>

                        <Link className="mb-3" to="/login">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              onClick={handleConfirm}
                            >
                                {loading ? <PulseLoader color="#fff" size={7} /> : "Confirm Email"}

                            </button>
                        </Link>
                    </div>
                    
                        
                  </div>
                </div>
              </div>
            {/* </Col> */}
            {/* <CarouselPage /> */}
          {/* </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailConfirmation;
