// ResourceList.js
import React, {useState, useEffect} from 'react';
import { Card, Button } from 'reactstrap';
import { ENDPOINTS, TOKEN } from '../../api/constants';
import axios from 'axios';
import ResourceModal from '../../components/CommonForBoth/Stoched/ResourceModal';
import { deleteResource, getResources } from '../../store/course/reducer';
import { useDispatch, useSelector } from "react-redux";
import baseUrl from '../../api';

// const baseUrl = process.env.REACT_APP_API_BASE;
const options = {
    headers: {
        Authorization: "Bearer " + TOKEN,
    },
};

const ResourceList = ({ workbooks, courseId, updateResourceList }) => {
    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false);
    const [resourceSelected, setResourceSelected] = useState({});

    const { deleting } = useSelector((state) => ({
        deleting: state.course.isDeleting,
        
    }));

    // useEffect(() => {
    //     dispatch(getResources({ 'courseId': courseId }))
    //     console.log('It has been deleted')
    // }, [deleted])

    // const handleDelete = (resource) => {
    //     setIsOpen(!isOpen);
    //     setResourceSelected(resource);
    // };

    const handleDownload = async (documentKey) => {
        let url = `${baseUrl}` + ENDPOINTS.downloadResource + `${documentKey}`;
        try {
            const response = await axios.post(url);
            const data = response.data.data;
            if (data) {
                const link = document.createElement('a');
                link.href = data;
                link.download = 'project-spreadsheet.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.log('Download link not available.');
            }
        } catch (error) {
            console.error('Error fetching or downloading the file:', error);
        }
    };

    const handleResourceDelete = (resource) => {

        // console.log("resource Id", resourceId)

        // Call an API to delete resource
        // Upon success, update the resource list
        // You can define this function in the parent component (AddResources) and pass it down as a prop
        // Example: updateResourceList(resourceId);

        const values = {
            'courseId': courseId,
            'documentKey': resource.documentKey,
        }

        dispatch(deleteResource(values))
    };

    return (
        <>
            <div className='p-2'>
                {workbooks?.map((workbook) => (
                    <div key={workbook._id} className="p-2">
                        <div className='d-flex justify-content-between'>
                            <div>{workbook.title}</div>
                            <div>
                                <Button
                                    color="success"
                                    size="sm"
                                    className="ms-2"
                                    onClick={() => handleDownload(workbook.documentKey)}
                                >
                                    Download
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    className="ms-2"
                                    onClick={() => handleResourceDelete(workbook)}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ResourceModal
                title="Remove Resource"
                resource={resourceSelected}
                isOpen={isOpen}
                onHide={setIsOpen}
                courseId={courseId}
                handleResourceDelete={handleResourceDelete}
            />
        </>
    );
};

export default ResourceList;
