import React, { useEffect } from "react";
import { Modal } from "reactstrap";
import { WindowClose } from "../../../assets/icons/Custom/custom";
import { useDispatch, useSelector } from "react-redux";
import { deleteResource, getResources } from "../../../store/course/reducer";
import { PulseLoader } from 'react-spinners';

const ResourceModal = (props) => {
    const dispatch = useDispatch();

    const { deleting, deleted } = useSelector((state) => ({
        deleting: state.course.isDeleting,
        deleted: state.course.deleted,
    }));

    // console.log('DELECTED', deleted)

    // useEffect(() => {
    //     dispatch(getResources({ courseId: props.courseId }))

    // }, [deleted])

    const removeWorkbook = () => {
        const values = {
            'courseId': props.courseId,
            'documentKey': props.resource.documentKey,
        }
        dispatch(deleteResource(values))
    };

    const handleCancel = () => {
        let payload = {
            'courseId': props?.courseId,
        }
        props.onHide(!props.isOpen)
        // dispatch(getResources(payload))
    }

    
  return (
    <>
       <Modal {...props} centered={true}>
       
       <React.Fragment>
         <div className="modal-header d-flex mt-0 justify-content-between">
           
               <h5 className="modal-title font-size-20">{props.title}</h5>
               <span onClick={handleCancel}>
                   <WindowClose />
               </span>
           </div>

         <div className="modal-body">
            <div>
                <h5>Are you sure you want to delete {props.resource.title}?</h5>
            </div>
            <div className="mb-4 d-flex justify-content-end">
                <button
                    className="btn btn-lg btn-outline-primary px-4 d-block mx-2"
                    type="submit"
                    onClick={handleCancel}
                >
                Cancel
                </button>
                <button
                    className="btn btn-lg btn-primary px-4 d-block"
                    type="submit"
                    onClick={removeWorkbook}
                >
                    {deleting? <PulseLoader color="#5fff" size={5} /> :<>Delete</> }
                </button>
            </div>
         </div>
       </React.Fragment>
    </Modal>
    </>
  )
}

export default ResourceModal
