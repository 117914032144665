import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";

//import steps
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

// Formik validation
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createBanner,
  createCourse,
  updatePrice,
  uploadQuiz,
  uploadProject,
  removeTitle,
  setTitle,
} from "../../store/course/reducer";
import * as Yup from "yup";

// import { createMerchantAccount, manualVerifyBusiness, unregisteredManualVerifyBusiness } from "../../../store/actions";

export const CreateCourse = () => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);

  const { id } = useParams();

  // const [bizType, setBizType] = useState("");
  const steps = ["Step1", "Step2", "Step3", "Step4"];
  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {}, [activeStep]);
  useEffect(() => {
    dispatch(removeTitle())
    dispatch(setTitle('Lets create your course '))
  }, [])

  const phoneRegex = RegExp(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );

  const validationSchema = [
    Yup.object().shape({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Required"),
      // banner: Yup.array().required("Required"),
    }),

    Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required("Link Name is required"),
          chapters: Yup.array()
            .of(
              Yup.object().shape({
                description: Yup.string().required("Required"),
                videoFile: Yup.mixed()
                  .required("Required")
                  .test(
                    "fileType",
                    "Unsupported file format",
                    (value) =>
                      value &&
                      ["video/mp4", "video/webm", "video/ogg"].includes(
                        value.type
                      )
                  ),
              })
            )
            .required("Chapters are required"),
        })
      )
      .required("Sections are required"),

    // Step 3
    Yup.object().shape({
      quizFile: Yup.mixed().nullable(),
      projectFile: Yup.mixed().nullable(),
    }),

    // Step 4
    Yup.object().shape({
      amount: Yup.number()
        .required("Amount is required")
        .min(0, "Amount must be greater than or equal to 0"),
    }),
  ];

  let currentValidationSchema;
  // if active step is 1 and business structure is 2, skip validation
  if (activeStep === 2) {
    currentValidationSchema = null;
  } else {
    currentValidationSchema = validationSchema[activeStep];
  }

  function _submitForm(values, actions) {
    new Promise((resolve) => setTimeout(resolve, 300));
    alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);
    // show success page
    if (isLastStep) {
      setActiveStep(3);
    }
  }

  const _handleSubmit = (values, actions) => {
    console.log("valuess", values);

    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 1) {
      //dispatch create course
      dispatch(createCourse(values));

      setTimeout(() => {
        dispatch(createBanner(values));
      }, 5000);

      setActiveStep(activeStep + 1);
    } else if (activeStep === 2) {
      setActiveStep(activeStep + 1);
      console.log('THE VALUES', values.hasProject)
      if (values.hasQuiz) {
        dispatch(uploadQuiz(values));
      }
      
      if (values.hasProject) {
      console.log('Hello')
        dispatch(uploadProject(values));
      } 
       
    } else if (isLastStep) {
      dispatch(updatePrice(values));
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  function _renderStepContent(step, setFieldValue, values) {
    switch (step) {
      case 0:
        return <Step1 setFieldValue={setFieldValue} />;
      case 1:
        return <Step2 values={values} setFieldValue={setFieldValue} />;
      case 2:
        return <Step3 values={values} setFieldValue={setFieldValue} />;
      case 3:
        return <Step4 values={values} />;
      default:
        return;
    }
  }

  //meta title
  document.title = "Welcome | Lets get you set up";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="my-4">
          {/* Render Breadcrumbs */}

          <Formik
            enableReinitialize="true"
            initialValues={{
              title: "",
              description: "",
              banner: null,
              hasQuiz: false,
              hasProject: false,

              sections: [
                {
                  title: "",
                  chapters: [
                    {
                      description: "",
                      video: "",
                      duration: {
                        hour: 0,
                        minute: 0,
                        seconds: 0,
                      },
                    },
                  ],
                },
              ],

              quizFile: null,
              projectFile: null,
              amount: 0,
            }}
            onSubmit={_handleSubmit}
            validationSchema={currentValidationSchema}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => (
              <Form className="needs-validation" id="onboardForm">
                {_renderStepContent(activeStep, setFieldValue, values)}

                <Row>
                  <Col xs={12} md={5}>
                    <div className="mt-4">
                      {activeStep !== 0 && (
                        <button
                          type="button"
                          className="btn btn-outline-primary waves-effect waves-light me-2"
                          onClick={() => setActiveStep(activeStep - 1)}
                        >
                          Back
                        </button>
                      )}
                      <button
                        className="btn btn-primary w-15 waves-effect waves-light"
                        type="submit"
                      >
                        {isLastStep ? "Create" : "Continue"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateCourse;
