import { call, put, takeEvery } from "redux-saga/effects";
import { changePassword, resetPassword } from "../../../api/requests/auths";
import { passwordResetFailure, passwordResetSuccess } from "./reducer";
import { toast } from "react-toastify";

function* resetPass({ payload: values }) {
    try {
        const response = yield call(resetPassword, values)

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          }) 

        yield put(passwordResetSuccess(response));

    } catch (error) {
      console.log('Reset error', error)
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      yield put(passwordResetFailure(error));
    
    }
  }


  function* changePass({ payload: values }) {

    try {
        const response = yield call(changePassword, {
          'oldPassword': values.oldPassword, 
          'newPassword': values.newPassword 
        })

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          }) 

        yield put(passwordResetSuccess(response));

    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
      yield put(passwordResetFailure(error));
    
    }
  }

  function* resetPassSaga(){
    yield takeEvery('resetPassword/passwordReset', resetPass)
    yield takeEvery('resetPassword/changePassword', changePass)
  }

  export default resetPassSaga