import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { ClipLoader } from 'react-spinners';

// users

import { logoutUser } from "../../../store/auths/logout/reducer";
import emptyImage from "../../../assets/images/users/empty.png"


const ProfileMenu = props => {
  const dispatch = useDispatch()

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  // const [username, setusername] = useState("Admin");

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.displayName);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.username);
  //     }
  //   }
  // }, [props.success]);

  const { loading, user } = useSelector(state => ({
    loading: state.logout.isLoading,
    user: state.login.user,    
  }))

  function handleLogout(){
    dispatch(logoutUser())
  }


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block profile"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user && user.profilePicture? user.profilePicture : emptyImage}
            alt="P"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{user.firstName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to='#' onClick={handleLogout}>
            <span className="dropdown-item d-flex" >
              <i className="bx bx-power-off font-size-17 align-middle me-1 mt-1 text-danger" />
              {loading ? <ClipLoader color="red" size={25} /> : <span className="font-size-16">{props.t("Logout")}</span>}
            </span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default 
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)
);