import React, { useEffect, useState } from "react";
import { TimeoutWarning } from "../../components/CommonForBoth/Modal/timeoutwarning";
import {
  addEventListeners,
  removeEventListeners,
} from "../../helpers/utils/eventListenerUtil";
import { useDispatch, useSelector } from "react-redux";
import { createRefreshToken } from "../../store/auths/login/reducer";
import { logoutUser } from "../../store/auths/logout/reducer";

export const SessionExpiryLogic = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.login.active,
  }));


  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [accessToken, setAccessToken] = useState(user?.token);
  const [expiresIn, setExpiresIn] = useState(user?.expires);

  // console.log('HEYY, user', user)

  // console.log('HEYY, access token', accessToken)
  // console.log('HEYY, expires in', expiresIn)
  // console.log('HEYY, warning modal', isWarningModalOpen)

  useEffect(() => {
    setAccessToken(user?.token)
    setExpiresIn(user?.expires)

  }, [user])
  
  useEffect(() => {

    // check if the expiration time is set and access token is available
    if (expiresIn && accessToken) {

      // calculate the time when the token will expire
      const expirationTime = new Date().getTime() + expiresIn * 1000;
      console.log('Token expirationTime', expirationTime)


      // calculate the remaining time until the token expires
      const remainingTime = expirationTime - new Date().getTime();
      // const remainingTime = 0;
      console.log('Remaining time', expirationTime)


      // set a timeout to handle token expiration or refresh
      const timeout = setTimeout(() => {
        if (remainingTime <= 0) {

          // token expired
          handleTokenExpiration();
        } else {
          // refresh the access token
          refreshAccessToken();
        }
      }, remainingTime);
      // return a cleanup function to clear the timeout when the component unmounts or the access token changes
      return () => clearTimeout(timeout);
    }
  }, [user]);


  useEffect(() => {
  
    const createTimeout2 = () => setTimeout(() => {
        // Implement a sign out function here
        dispatch(logoutUser());

    }, 10000);

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
      }
    };

    // Initialization
    let timeout = isWarningModalOpen ? createTimeout2() : '';
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);


  // function to handle token expiration
  const handleTokenExpiration = () => {
    // clear the access token, refresh token, and expiration time from the state
    setAccessToken(null);
    setExpiresIn(null);

    setWarningModalOpen(true);
    // perform logout or redirect to login page if after 10mins no action is performed

    // ...
  };

  // function to refresh the access token
  const refreshAccessToken = () => {
    setWarningModalOpen(true);

    // make a request to the server to refresh the access token using the refresh token
    // ...
    const data = {
      'storedToken': user?.refreshToken,
    }

    dispatch(createRefreshToken(data))

    // update the access token, refresh token, and expiration time in the state
    // ...

    //close the modal

    setTimeout(() => {
      // Implement a sign out function here
      setWarningModalOpen(false);

    }, 4000);
  };


  // code to handle login and set access token, refresh token, and expiration time in the state
  // ...



  return (
    <div>
      {isWarningModalOpen && (
        <TimeoutWarning
          isOpen={isWarningModalOpen}
          onRequestClose={refreshAccessToken}
          title="Token Expired"
          description="You're being timed out due to token expiry. Please choose to refresh token or to logoff. Otherwise, you will be logged off automatically"
          primaryButton="Refresh token"
        />
      )}
    </div>
  );

}

export default SessionExpiryLogic;