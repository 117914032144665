import React from 'react'

const Note = () => {
  return (
    <div className='mt-2'>
      <h5 className='my-3'>Functional Model</h5>
      <p>
        In systems engineering, software engineering, and computer science, a function model or functional model is a structured representation of the
            functions (activities, actions, processes, operations) within the modeled system or subject area. Example of a function model of the process of
            "Maintain Reparable Spares" in IDEF0 notation. A function model, similar with the activity model or process model, is a graphical
            representation of an enterprise's function within a defined scope. The purposes of the function model are to describe the functions and
            processes, assist with discovery of information needs, help identify opportunities, and establish a basis for determining product and service
            costs. (wikipedia)
        </p>
    </div>
  )
}

export default Note
