import React from 'react'
import ReactEcharts from "echarts-for-react"

const DemoChart = (data) => {

    const pullOption = () => {

        return {
          toolbox: {
            show: !1,
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
          },

          color: [  '#E06262', '#65A36F', '#47ADD9',],
          series: [
            {
              name: "Total sales",
              type: "pie",
              radius: ["50%", "70%"],
              avoidLabelOverlap: !1,
              label: {
                normal: {
                  show: !1,
                  position: "center",
                },
                emphasis: {
                  show: !0,
                  textStyle: {
                    fontSize: "30",
                    fontWeight: "bold",
                  },
                },
              },
              labelLine: {
                normal: {
                  show: !1,
                },
              },
              data: [
                { value: data.data?.engineerPercentage, name: "Professional" },
                { value: data.data?.studentUserPercentage, name: "Students" },
                { value: data.data?.demographyOthersPercentage, name: "Others" },
              ],
            },
          ],
        }
      }

  return (
    <>
        <ReactEcharts option={pullOption()} style={{ height: "315px"}}/>
        
    </>
  )
}

export default DemoChart
