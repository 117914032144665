import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { filterEnrolledCoursesById, removeTitle, setTitle } from '../../store/course/reducer';
import axios from 'axios';
import { ENDPOINTS, TOKEN } from '../../api/constants';
import baseUrl from '../../api';
// const baseUrl = process.env.REACT_APP_API_BASE;

import avtar5 from "../../assets/images/users/avatar-5.jpg";
import { ProjectIcon, QuizIcon } from '../../assets/icons/Custom';


const options = {
    headers: {
      Authorization: "Bearer " + TOKEN,
    },
};

const index = () => {
    const dispatch = useDispatch();
    const { studentId, courseId } = useParams();

    const { filteredEnrolled  } = useSelector(state => ({
        filteredEnrolled: state.course.filteredEnrolled,
    }))

    let url = `${baseUrl}` + ENDPOINTS.downloadProject + `${filteredEnrolled?.studentProject}`;


    // console.log('enrolled student', filteredEnrolled)

    useEffect(() => {
        const data = {
            studentId: studentId,
            courseId: courseId,
        }

        dispatch(filterEnrolledCoursesById(data))
        dispatch(removeTitle())
        dispatch(setTitle(`Submission Portal`))
    }, [])


    // Check if studentProject is "No Submission"
    const isSubmissionAvailable = !filteredEnrolled?.projectSubmission

      const handleDownload = async () => {
        try {
          // Make a POST request to your API endpoint
          const response = await axios.get(url);
    
    
          console.log('Project URL', response)
    
          const data = response.data.data;
    
          console.log('project response data..', data)
    
          if (data) {
            // Update the state with the download link
            // setDownloadLink(data.downloadLink);
    
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = data;
            link.download = 'project-spreadsheet'; // Set the desired file name here
            document.body.appendChild(link);
    
            // Trigger the download
            link.click();
    
            // Clean up the temporary anchor element
            document.body.removeChild(link);

            console.log('downloaded true')

          } else {
            console.log('Download link not available.');
          }
        } catch (error) {
          console.error('Error fetching or downloading the file:', error);
        }
      }

  return (
    <div className="page-content">
        <Container fluid>
            <Row className="mt-3 w-80">
                {/* <h5 className='mb-4 font-size-18'>Submission Portal</h5> */}
                  <Col xl={9}>
                      <Row>
                          <Col xl={6} sm={6}>
                            <Card className='card-courses'>
                                <CardBody className="d-flex">
                                    <div className="submission">
                                      <div className="flex-shrink-0 me-3">
                                          <img className="rounded avatar-xl" src={filteredEnrolled.profileUrl} alt="" />
                                      </div>
                                    </div>
                                    <div>
                                      <h5>{filteredEnrolled?.name}</h5>
                                      <p>{filteredEnrolled?.course}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} sm={6}>
                            <Card className='card-courses'>
                                <CardBody className="overflow-hidden position-relative">
                                    <div className="">
                                        <span className='mb-4'>
                                          <QuizIcon/>
                                        </span>
                                        <h6 className="text-muted mt-2 mx-2">Quiz</h6>
                                    </div>
                                    <h3 className="mx-2 mb-0">{filteredEnrolled?.quiz}</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} sm={6}>
                            <Card className='card-courses'>
                                <CardBody className="overflow-hidden position-relative">
                                    <div className="">
                                        <span className='mb-4'>
                                          <ProjectIcon/>
                                        </span>
                                        <h6 className="text-muted mt-2 mx-2">Project</h6>
                                    </div>
                                    <Button className={`btn btn-sm waves-effect waves-light mx-2`} onClick={handleDownload} disabled={isSubmissionAvailable}>{isSubmissionAvailable? "No Submission" : "Download"}</Button>
                                </CardBody>
                            </Card>
                        </Col>
                      </Row>
                  </Col>
            </Row>

        </Container>
      
    </div>
  )
}

export default index
