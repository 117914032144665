import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Nav, NavItem, NavLink, TabPane, TabContent, } from 'reactstrap'
import classnames from "classnames";
import ProfileTab from './ProfileTab';
import AuthenticationTab from './AuthenticationTab';
import PaymentTab from './PaymentTab';
// import { getAllVerificationTypes, getBusinessRegTypes, getAllCountries, getBusinessTypes } from '../../store/actions';
import { useDispatch } from "react-redux";
import { removeTitle, setTitle } from '../../store/course/reducer';

const Settings = () => {
  const dispatch = useDispatch();

  const [mainHeaderTab, setMainHeaderTab] = useState("1");

  const toggleMainHeader = (tab) => {
    if (mainHeaderTab !== tab) setMainHeaderTab(tab);
  };

//   useEffect(() => {
//     dispatch(getBusinessRegTypes());
//     dispatch(getAllCountries());
//     dispatch(getBusinessTypes());
//     dispatch(getAllVerificationTypes());
//   }, []);

useEffect(() => {
    dispatch(removeTitle())
    dispatch(setTitle(`Settings`))
}, [])

  return (
    <div className='page-content'>
        <Container fluid>
          <Row className="page-subheading-style pb-3 pt-3">
            <Col className="col-12">
              <div className="align-items-center">
                <div className="flex-grow-0 settings">
                  <Nav tabs className="nav-tabs-custom">
                    <NavItem> 
                        <NavLink
                            to="#"
                            className={classnames({
                                active: mainHeaderTab === "1",
                            })}
                            onClick={() => {
                                toggleMainHeader("1");
                            }}
                            >
                            <span className="d-none d-sm-block">Profile</span>
                        </NavLink>
                    </NavItem>
                    <NavItem> 
                        <NavLink
                            to="#"
                            className={classnames({
                                active: mainHeaderTab === "2",
                            })}
                            onClick={() => {
                                toggleMainHeader("2");
                            }}
                            >
                            <span className="d-none d-sm-block">Authentication</span>
                        </NavLink>
                    </NavItem>
                    {/* <NavItem> 
                        <NavLink
                            to="#"
                            className={classnames({
                                active: mainHeaderTab === "3",
                            })}
                            onClick={() => {
                                toggleMainHeader("3");
                            }}
                            >
                            <span className="d-none d-sm-block">Payment</span>
                        </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="">
                <Col>
                    <TabContent activeTab={mainHeaderTab}>
                        <TabPane tabId="1">
                            <ProfileTab/>
                        </TabPane>
                        <TabPane tabId="2">
                            <AuthenticationTab/>
                        </TabPane>
                        {/* <TabPane tabId="3">
                            <PaymentTab/>
                        </TabPane> */}
                    </TabContent>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Settings
