import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";

//import steps
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

// Formik validation
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createBanner,
  updatePrice,
  uploadQuiz,
  uploadProject,
  updateCourse,
  removeTitle,
  setTitle,
  getCourse,
} from "../../store/course/reducer";
import * as Yup from "yup";

export const UpdateCourse = () => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [selected, setSelected] = useState({});

  const { course_id, price, fetchedCourse } = useSelector((state) => ({
    course_id: state.course.response,
    price: state.course.price,
    fetchedCourse: state.course.selected,
  }));

  // console.log('Fetched Course', fetchedCourse)
  // console.log('Fetched Course id', course_id)

  useEffect(() => {
    const select = {
      id: fetchedCourse?._id,
      title: fetchedCourse?.title,
      description: fetchedCourse?.description,
      banner: fetchedCourse?.courseBanner,
      hasProject: fetchedCourse?.hasProject,
      hasQuiz: fetchedCourse?.hasQuiz,
      sections: fetchedCourse?.sections,

      quizFile: fetchedCourse?.project,
      projectFile: fetchedCourse?.quiz,
      amount: fetchedCourse?.price,
    };

    setSelected(select);
  }, [fetchedCourse]);

  const { id } = useParams();

  // console.log('updated course', id)

  const steps = ["Step1", "Step2", "Step3", "Step4"];
  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {
    // console.log('steps', activeStep)
  }, [activeStep]);

  useEffect(() => {
    dispatch(getCourse(id))
    dispatch(removeTitle())
    dispatch(setTitle('Lets update your course'))
    
  }, [id])

  const phoneRegex = RegExp(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );

  const validationSchema = [
    Yup.object().shape({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Required"),
      // banner: Yup.array().required("Required"),
    }),

    Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required("Link Name is required"),
          chapters: Yup.array()
            .of(
              Yup.object().shape({
                description: Yup.string().required("Required"),
                videoFile: Yup.mixed()
                  .required("Required")
                  .test(
                    "fileType",
                    "Unsupported file format",
                    (value) =>
                      value &&
                      ["video/mp4", "video/webm", "video/ogg"].includes(
                        value.type
                      )
                  ),
              })
            )
            .required("Chapters are required"),
        })
      )
      .required("Sections are required"),
  ];

  const currentValidationSchema = validationSchema[activeStep];

  function _submitForm(values, actions) {
    new Promise((resolve) => setTimeout(resolve, 300));
    alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);
    // show success page
    if (isLastStep) {
      setActiveStep(3);
    }
  }

  const _handleSubmit = (values, actions) => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 1) {
      const payload = {
        title: values.title,
        description: values.description,
        banner: values.banner,
        sections: values.sections,
        quizFile: values.quizFile,
        projectFile: values.projectFile,
        courseId: id,
      };

      //dispatch update course
      dispatch(updateCourse(payload));

      if(!fetchedCourse.hasBanner){
        setTimeout(() => {
          dispatch(createBanner(values));
        }, 5000);
      }

     

      setActiveStep(activeStep + 1);
    } else if (activeStep === 2) {
      setActiveStep(activeStep + 1);
      console.log('Test Quiz', values.quizFile)

      if(!fetchedCourse.hasQuiz ){
        dispatch(uploadQuiz(values));
      }else{
        return;
      }

      if(!fetchedCourse.hasProject){
        dispatch(uploadProject(values));
      }else{
        return;
      }

    } else if (isLastStep) {
      dispatch(updatePrice(values));
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  function _renderStepContent(step, setFieldValue, values) {
    switch (step) {
      case 0:
        return <Step1 setFieldValue={setFieldValue} data={fetchedCourse}/>;
      case 1:
        return <Step2 values={values} setFieldValue={setFieldValue} />;
      case 2:
        return <Step3 values={values} setFieldValue={setFieldValue} data={fetchedCourse}/>;
      case 3:
        return <Step4 values={values} />;
      default:
        return;
    }
  }

  //meta title
  document.title = "Welcome | Lets get you set up";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="my-4">
          {/* Render Breadcrumbs */}

          <Formik
            enableReinitialize="true"
            // initialValues={selected}
            initialValues={
              fetchedCourse
                ? selected
                : {
                    title: "",
                    description: "",
                    banner: null,
                    hasQuiz: false,
                    hasProject: false,

                    sections: [
                      {
                        title: "Section 1",
                        chapters: [
                          {
                            description: "Chapter 1",
                            video: "",
                            duration: {
                              hour: 0,
                              minute: 0,
                              seconds: 0,
                            },
                          },
                        ],
                      },
                    ],

                    quizFile: null,
                    projectFile: null,
                    amount: 0,
                  }
            }
            onSubmit={_handleSubmit}
            validationSchema={currentValidationSchema}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => (
              <Form className="needs-validation" id="onboardForm">
                {_renderStepContent(activeStep, setFieldValue, values)}

                <Row>
                  <Col xs={12} md={8}>
                    <div className="mt-5">
                      {activeStep !== 0 && (
                        <button
                          type="button"
                          className="me-2 btn btn-outline-primary waves-effect waves-light"
                          onClick={() => setActiveStep(activeStep - 1)}
                        >
                          Back
                        </button>
                      )}
                      <button
                        className="btn btn-primary w-15 waves-effect waves-light"
                        type="submit"
                      >
                        {isLastStep ? "Create Course" : "Continue"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UpdateCourse;
