import React, {useState, useEffect } from "react"
import { Row, Col, Alert, Container, Input, Label, Form, FormFeedback  } from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom"

import { PulseLoader } from 'react-spinners';

// import images
import stoched from "../../assets/images/stoched_.png"

import CarouselPage from "../AuthenticationInner/CarouselPage"
import { createRegister } from "../../store/auths/register/reducer";

const Register = props => {

  //meta title
  document.title = "Register | Stoched";

  const [showHidePassword, setShowHidePassword] = useState(false);

  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.register.isLoading,
  }))

  console.log('Signing up', loading)


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your Firstname"),
      lastName: Yup.string().required("Please Enter Your Lastname"),
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[^\w]/, "Password requires a symbol"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(createRegister(values));
      resetForm({ values: ''})
    }
  });

  useEffect(() => {
    // dispatch(apiError(""))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="overlay">
          <div className="d-flex justify-content-center pt-5">
            <Link to="/dashboard" className="d-block auth-logo py-3">
              <img src={stoched} alt="" height="35" /> <span className="logo-txt text-white">Stoched</span>
            </Link>
          </div>
        <Container className="auth-page p-sm-5 p-4">
           
                    
          <div className="auth-content">
            <div className="">
                <p className="text-muted mb-0">Already have an account ? <Link to="/login"
                className="text-primary fw-semibold"> Log in </Link> </p>
            </div>

            <Form
              className="needs-validation custom-form mt-4 pt-2"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mb-3">
                <Label className="form-label">Firstname</Label>
                <Input
                  name="firstName"
                  type="text"
                  placeholder="Enter firstname"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName || ""}
                  invalid={
                    validation.touched.firstName && validation.errors.firstName ? true : false
                  }
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Lastname</Label>
                <Input
                  name="lastName"
                  type="text"
                  placeholder="Enter lastname"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastName || ""}
                  invalid={
                    validation.touched.lastName && validation.errors.lastName ? true : false
                  }
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email ? true : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Password</Label>
                <div className="input-group dashpass">
                  <Input
                    name="password"
                    value={validation.values.password || ""}
                    type={showHidePassword ? "text" : "password"}
                    placeholder="Enter Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password && validation.errors.password ? true : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                  ) : null}
                  {!validation.errors.password && (
                  <span
                      className="input-group-text"
                      onClick={() =>
                      setShowHidePassword(!showHidePassword)
                      }
                  >
                      {showHidePassword ? (
                      <i className="mdi text-muted fs-4 mdi-eye"></i>
                      ) : (
                      <i className="mdi text-muted fs-4 mdi-eye-off"></i>
                      )}
                  </span>
                  )}
                </div>
              </div>
              
              <div className="my-4 ">
                <p className="mb-0 font-size-12">
                  By registering you agree to the Stoched{" "}
                  <Link to="#" className="text-primary">
                    Terms of Use
                  </Link>
                </p>
              </div>
              <div className="mb-3">
                <button
                  className="btn btn-primary w-100 waves-effect waves-light"
                  type="submit"
                  disabled={
                    !(validation.dirty && validation.isValid) ||
                    validation.isSubmitting
                  }
                >
                    {loading ? <PulseLoader color="#fff" size={7} /> : "Register"}

                </button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register