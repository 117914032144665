import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  
  component: Component,
  layout: Layout,
  isAuthProtected,
  timeout: TimeoutLogic,
  session: SessionExpiryLogic,
  ...rest
}) => (<Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("token")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
          <TimeoutLogic/>
          <SessionExpiryLogic/>
        </Layout>
      )
    }}
  />
  )

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
