import React, { useState, useEffect } from 'react';

import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
import WalletBalance from './WalletBalance';
import InvestedOverview from './InvestedOverview'
import { getStats, getUser } from '../../store/auths/login/reducer';
import { useDispatch, useSelector } from "react-redux";
import { StatSkeleton } from '../../components/CommonForBoth/Stoched/StatSkeleton';
import { BalanceIcon, CertificateIcon, CourseIcon, OtherIcon, ProfessionalIcon, StudIcon, StudentIcon } from '../../assets/icons/Custom';
import DemoChart from './DemoChart';
import FindChart from './FindChart';
import { removeTitle, setTitle } from '../../store/course/reducer';
import { roundToSignificantFigures, roundToWholeNumber } from '../../helpers/utils/otherUtils';

const Dashboard = () => {
    const dispatch = useDispatch();

    const { stats, loading } = useSelector(state => ({
        stats: state.login.stats,
        loading: state.login.isFetching,
      }))

    const [isShown, setIsShown] = useState(false);
    const [ selected, setSelected ] = useState({})

    useEffect(() => {
        dispatch(getUser())
        dispatch(getStats())

        dispatch(removeTitle())
        dispatch(setTitle('Dashboard'))
    },[])

    const sizes = [1,2,3,4]
    const others = [1,2]

    console.log('environment', process.env.NODE_ENV)

    //meta title
    document.title="Dashboard | Minia - React Admin & Dashboard Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        {loading ? (
                            sizes.map((size, i) => (
                                <Col xl={3} lg={4} sm={6} key={i}>
                                    <Card>
                                        <CardBody>
                                                <StatSkeleton/>
                                            </CardBody>
                                        </Card>
                                </Col>
                            ))
                        ) :
                        <>
                        <Col xl={3} md={6}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6} className=''>
                                                <div className='me-3 my-2'>
                                                   {/* <MdAccountBalanceWallet size={30} color="green"/>  */}
                                                   <BalanceIcon/>
                                                   <span className="lh-1 d-block text-truncate font-size-14 my-2 text-muted">Balance</span>
                                                </div>
                                               <div>
                                                    <h4 className="mt-3">
                                                    $
                                                        <span className="counter-value">
                                                            {stats?.totalAmountEarned}
                                                        </span>
                                                    </h4>
                                               </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={3} md={6}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6} className=''>
                                                <div className='me-3 my-2'>
                                                    {/* <FaBookOpen size={30} color="#A66FF0"/>       */}
                                                    <CourseIcon/>
                                                    <span className="lh-1 d-block text-truncate font-size-14 my-2 text-muted">Courses</span>
                                                </div>
                                               <div>
                                                    <h4 className="mt-3">
                                                        <span className="counter-value">
                                                            {stats?.courseCount}
                                                        </span>
                                                    </h4>
                                               </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={3} md={6}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6} className=''>
                                                <div className='me-3 my-2'>
                                                    {/* <BsPeopleFill size={30} color="#FF7E86"/>  */}
                                                    <StudentIcon/>
                                                    <span className="lh-1 d-block text-truncate font-size-14 my-2 text-muted">Students</span>
                                                </div>
                                               <div>
                                                    <h4 className="mt-3">
                                                        <span className="counter-value">
                                                            {stats?.totalStudentsCount}
                                                        </span>
                                                    </h4>
                                               </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={3} md={6}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6} className=''>
                                                <div className='me-3 my-2'>
                                                    {/* <AiFillSafetyCertificate size={30} color="#F6CC0D"/>    */}
                                                    <CertificateIcon/>
                                                    <span className="lh-1 d-block text-truncate font-size-14 my-2 text-muted">Certified</span>
                                                </div>
                                               <div>
                                                    <h4 className="mt-3">
                                                        <span className="counter-value">
                                                            {stats?.certifiedStudents}
                                                        </span>
                                                    </h4>
                                               </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </>
                        }

                    </Row>
                    <Row>
                    {loading ? (
                            others.map((size, i) => (
                                <Col xl={6} lg={6} sm={6} key={i} className='mt-4'>
                                   <Card>
                                        <CardBody>
                                            <StatSkeleton/>
                                        </CardBody>
                                   </Card>
                                </Col>
                            ))
                        ) : 
                        <>     
                            {/* <DemoChart/>  */}
                            {/* <WalletBalance stats={stats?.demography}/> */}
                            {/* <InvestedOverview stats={stats?.howYouFoundUs}/>  */}
                            <Col xl={7} lg={7} sm={6}>
                                <Card>
                                      <CardBody>
                                        <div className="d-flex flex-wrap align-items-center">
                                            <h5 className="card-title me-2">Demography</h5>
                                        </div>
                                        <Row>
                                            <div className="col-sm">
                                                <div id="wallet-balance" className="apex-charts">
                                                    <div id="wallet-balance" className="apex-charts">
                                                        <DemoChart data={stats?.demography}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm align-self-center demo-chart">
                                                <div className='d-flex justify-content-between '>
                                                    <div className='mx-2'><ProfessionalIcon/> <span className='mx-2 font-size-14'>Professional</span></div>
                                                    <div> {roundToWholeNumber(stats?.demography.engineerPercentage)}%</div>
                                                </div>
                                                <div className='d-flex justify-content-between my-3'>
                                                    <div className='mx-2'><StudIcon/> <span className='mx-2 font-size-14'>Students</span></div>
                                                    <div>{roundToWholeNumber(stats?.demography.studentUserPercentage)}%</div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='mx-2'><OtherIcon/> <span className='mx-2 font-size-14'>Others</span></div>
                                                    <div>{roundToWholeNumber(stats?.demography.demographyOthersPercentage)}%</div>
                                                </div>
                                            </div>
                                        </Row>
                                      </CardBody>
                                </Card>
                            </Col>
                            <Col xl={5} lg={5} sm={6}>
                                <Card>
                                      <CardBody>
                                        <div className="align-items-center">
                                            <h5 className="card-title me-2">How did you find us?</h5>
                                        </div>
                                        <FindChart data={stats?.howYouFoundUs}/>
                                      </CardBody>
                                </Card>
                            </Col>
                        </>
                    }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;