import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  price: null,
  response: null,
  selectedTitle: '',
  isLoading: false,
  loading: false,
  url: '',
  courses: [],
  selected: [],
  enrolled: null,
  filteredEnrolled: [],
  isUploading: false,
  isDeletingQuiz: false,
  isDeletingProject: false,
  resources: null,
  deleteResponse: '',
  deleted: '',
  resourceUploadResponse: '',

};

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    //Create Course
    createCourse: (state) => {
        state.isLoading = true
    },
    createCourseSuccess: (state, action) => {
      state.response = action.payload;
      state.isLoading = false
    },
    createCourseFailure: (state) => {
        state.isLoading = false
    },
    setTitle: (state, action) => {
      state.selectedTitle = action.payload
    },
    removeTitle: (state) => {
      state.selectedTitle = ''
    },

    //Delete Course
    deleteCourse: (state) => {
      state.isLoading = true
    },
    deleteCourseSuccess: (state, action) => {
      state.response = action.payload;
      state.isLoading = false

    },
    deleteCourseFailure: (state) => {
        state.isLoading = false
    },

    //Course Banner
    createBanner: (state) => {
        state.isLoading = true
    },
    createBannerSuccess: (state, action) => {
      state.response = action.payload.data;
      state.isLoading = false

    },
    createBannerFailure: (state) => {
        state.isLoading = false
    },

     //Delete Banner
    deleteBanner: (state) => {
      state.isDeleting = true
    },
    deleteBannerSuccess: (state, action) => {
      state.deleteResponse = action.payload;
      state.isDeleting = false

    },
    deleteBannerFailure: (state) => {
        state.isDeleting = false
    },

    //Upload Quiz
    uploadQuiz: (state) => {
      state.isLoading = true
    },
    uploadQuizSuccess: (state, action) => {
      state.url = action.payload;
      state.isLoading = false
    },
    uploadQuizFailure: (state) => {
        state.isLoading = false
    },

    //Delete Quiz
    deleteQuiz: (state) => {
      state.isDeletingQuiz = true
    },
    deleteQuizSuccess: (state, action) => {
      state.deleteResponse = action.payload;
      state.isDeletingQuiz = false
    },
    deleteQuizFailure: (state) => {
        state.isDeletingQuiz = false
    },

     //Upload Project
    uploadProject: (state) => {
      state.isLoading = true
    },
    uploadProjectSuccess: (state, action) => {
      state.url = action.payload;
      state.isLoading = false
    },
    uploadProjectFailure: (state) => {
        state.isLoading = false
    },

    //Delete Project
    deleteProject: (state) => {
      state.isDeletingProject = true
    },
    deleteProjectSuccess: (state, action) => {
      state.deleteResponse = action.payload;
      state.isDeletingProject = false
    },
    deleteProjectFailure: (state) => {
      state.isDeletingProject = false
    },

    //Course Price
    updatePrice: (state) => {
      state.isLoading = true
    },
    updatePriceSuccess: (state, action) => {
      state.price = action.payload.data;
      state.isLoading = false
    },
    updatePriceFailure: (state) => {
        state.isLoading = false
    },

    //Fetch Courses
    getCourses: (state) => {
      state.isLoading = true
    },
    getCoursesSuccess: (state, action) => {
      state.courses = action.payload;
      state.isLoading = false
    },
    getCoursesFailure: (state) => {
        state.isLoading = false
    },

    //Fetch A Course
    getCourse: (state) => {
      state.isLoading = true
    },
    getCourseSuccess: (state, action) => {
      state.selected = action.payload;
      state.isLoading = false
    },
    getCourseFailure: (state) => {
        state.isLoading = false
    },

    //Update A Course
    updateCourse: (state) => {
      state.isLoading = true
    },
    updateCourseSuccess: (state, action) => {
      state.response = action.payload;
      state.isLoading = false
    },
    updateCourseFailure: (state) => {
        state.isLoading = false
    },

    //Student Enrolled Course
    coursesEnrolled: (state) => {
      state.loading = true
    },
    coursesEnrolledSuccess: (state, action) => {
      state.enrolled = action.payload;
      state.loading = false
    },
    coursesEnrolledFailure: (state) => {
        state.loading = false
    },

    // New action to filter enrolled course by student ID and course ID
    filterEnrolledCoursesById: (state, action) => {
      const data = action.payload;

      // Filter enrolled courses based on student ID
      state.filteredEnrolled = state.enrolled?.filteredStudentList.find(
        (course) => course.studentId === data.studentId && course.courseId === data.courseId
      );
    },

    //Upload Resource
    uploadResource: (state) => {
      state.isUploading = true
    },
    uploadResourceSuccess: (state, action) => {
      state.resourceUploadResponse = action.payload;
      state.isUploading = false
    },
    uploadResourceFailure: (state) => {
        state.isUploading = false
    },
    refreshUpload: (state) => {
      state.resourceUploadResponse = '';
    },

    //Fetch Resources
    getResources: (state) => {
      state.isFetching = true
    },
    getResourcesSuccess: (state, action) => {
      state.resources = action.payload;
      state.isFetching = false
    },
    getResourcesFailure: (state) => {
        state.isFetching = false
    },

    //Delete Resource
    deleteResource: (state) => {
      state.isDeleting = true
    },
    deleteResourceSuccess: (state, action) => {
      state.deleted = action.payload;
      state.isDeleting = false
    },
    deleteResourceFailure: (state) => {
        state.isDeleting = false
    },
    refreshDeleteResource: (state) => {
      state.deleted = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
  updateCourse, updateCourseSuccess, updateCourseFailure,
  getCourse, getCourseSuccess, getCourseFailure, deleteCourse, deleteCourseSuccess, deleteCourseFailure, getCourses, getCoursesSuccess, getCoursesFailure, uploadProject, uploadProjectSuccess, uploadProjectFailure, updatePrice, updatePriceSuccess, updatePriceFailure, createBanner, createBannerSuccess, createBannerFailure, createCourse, createCourseSuccess, createCourseFailure, uploadQuiz, uploadQuizSuccess, uploadQuizFailure,
  coursesEnrolled, coursesEnrolledSuccess, coursesEnrolledFailure,
  filterEnrolledCoursesById,
  uploadResource, uploadResourceSuccess, uploadResourceFailure, refreshUpload,
  getResources, getResourcesSuccess, getResourcesFailure,
  deleteResource, deleteResourceSuccess, deleteResourceFailure, refreshDeleteResource,
  deleteBanner, deleteBannerSuccess, deleteBannerFailure,
  deleteQuiz, deleteQuizSuccess, deleteQuizFailure,
  deleteProject, deleteProjectSuccess, deleteProjectFailure,
  setTitle, removeTitle,
  } =
courseSlice.actions;

export const courseSelector = (state) => state.course;

export default courseSlice.reducer;
