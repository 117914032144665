// UploadResource.js
import React, { useState, useEffect } from 'react';
import Dropzone from "react-dropzone";
import { Card, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getResources, refreshDeleteResource, refreshResource, refreshUpload, uploadResource } from '../../store/course/reducer';
import { PulseLoader } from 'react-spinners';
import { UploadIcon } from '../../assets/icons/Custom';

const UploadResource = ({ courseId, updateResourceList }) => {
    const dispatch = useDispatch();
    const { loading, response, deleted } = useSelector(state => ({
        loading: state.course.isUploading,
        response: state.course.resourceUploadResponse,
        deleted: state.course.deleted,
    }));

    console.log('Delete State', deleted)
    console.log('Fetch Resource State', response)

    useEffect(() => {
      localStorage.setItem("courseResourceId", courseId)
    }, [])

    useEffect(() => {
      console.log('It has been deleted')

      dispatch(getResources({ courseId: courseId }))
      dispatch(refreshUpload())
      dispatch(refreshDeleteResource())
    }, [response, deleted])

    const handleUpload = (files) => {
      dispatch(uploadResource({courseId: courseId, files: files}));
      dispatch(getResources({ courseId: courseId }))
    };


    return (
        <div>
            <Dropzone
                onDropAccepted={handleUpload}
                accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                maxFiles={1}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone mt-3">
                        <div className="dz-message needsclick mt-2" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="mb-1">
                                {/* <i className="display-5 text-muted bx bx-cloud-upload" /> */}
                                <UploadIcon/>
                            </div>
                                <h6>Select file or drop it</h6>
                                <small className="text-muted fs-6 fw-light">
                                (Max. File Size 25MB)
                                </small>
                        </div>
                    </div>
                )}
            </Dropzone>
            {loading &&
                <div className='float-end mt-3'>
                    <Button disabled={loading}>
                      Uploading <PulseLoader color="#fff" size={7} />    
                    </Button>
                </div>
            }
        </div>
    );
};

export default UploadResource;
