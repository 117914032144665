import React, { useState, useRef  } from "react";
import {
  Col,
  Row,
  Input,
  Label,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap";
// import { Field } from "formik";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { uploadVideo } from "../../store/course/reducer";
import { ClipLoader, BarLoader } from "react-spinners";
import { ENDPOINTS } from "../../api/constants";
import baseUrl from "../../api";
import { AddCourseIcon, DeleteCourseIcon, UploadIcon2 } from "../../assets/icons/Custom";
// const baseUrl = process.env.REACT_APP_API_BASE;

const Step2 = ({ setFieldValue, values }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chapterLoading, setChapterLoading] = useState([]);
  const dispatch = useDispatch();

  const inputRef = useRef();

  const handleVideoUpload = async (file, index, chapterIndex) => {
    try {
      // Upload the video file to the endpoint and get the URL
      const formData = new FormData();
      formData.append("video", file);

      // Set loading state for the specific chapter
      setChapterLoading((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = updatedLoading[index] || [];
        updatedLoading[index][chapterIndex] = true;
        return updatedLoading;
      });

      let url = `${baseUrl}` + ENDPOINTS.uploadVideo;
      const response = await axios.post(url, formData);
      const videoUrl = response.data.data.videoKey;
      const duration = response.data.data.duration;

      // Clear loading state for the specific chapter after upload
      setChapterLoading((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index][chapterIndex] = false;
        return updatedLoading;
      });

      const updatedCourse = { ...values };
      updatedCourse.sections[index].chapters[chapterIndex].video = videoUrl;
      updatedCourse.sections[index].chapters[chapterIndex].duration = duration;
      setFieldValue(updatedCourse);
    } catch (error) {
      console.error("Error uploading video:", error);

      // Clear loading state for the specific chapter in case of an error
      setChapterLoading((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index][chapterIndex] = false;
        return updatedLoading;
      });
    }
  };

  return (
    <React.Fragment>
      <div>
      <FieldArray name="sections">
            {({ insert, remove, push }) => (
              <div>
                 <div className="d-flex justify-content-between">
                  <div>
                    <h3 className="page-title fs-heading font-weight-bold">
                      Section & Chapter
                    </h3>
                    <p className="mb-3 text-muted">
                      Stoched collect this information to create your course contents.
                    </p>
                  </div>

                  <button className="btn btn-success btn-rounded waves-effect waves-light my-3"
                    type="button"
                    onClick={() =>
                      push({
                        title: "",
                        chapters: [{ description: "", video: "" }],
                      })
                    }
                  >
                    <AddCourseIcon color="#fff"/>

                    Add Section
                  </button>
                </div>

                <p className="mb-4 font-weight-bold">Step 2 of 4</p>


                {values.sections?.length > 0 &&
                  values.sections?.map((section, index) => (
                    <>
                    <Row key={index}>
                      <Col xs={12} md={5} xl={5}>
                      <Card>
                        <CardBody>
                          <div>
                            <Label
                              htmlFor={`sections.${index}.title`}
                              className="form-label"
                            >
                              {" "}
                              Section {index + 1}{" "}
                            </Label>
                            <Field name={`sections.${index}.title`}>
                              {({ field, form }) => (
                                <div className="mb-1">
                                  <Input
                                    {...field}
                                    name={`sections.${index}.title`}
                                    type="text"
                                    placeholder={`Enter section ${index + 1} title`}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    // value={form.values.section || ""}
                                    invalid={
                                      form.touched.section &&
                                      form.errors.section
                                        ? true
                                        : false
                                    }
                                  />
                                  {form.errors.sections &&
                                    form.errors.sections[index] &&
                                    form.errors.sections[index].title && (
                                      <FormFeedback>
                                        {errors.sections[index].title}
                                      </FormFeedback>
                                    )}
                                </div>
                              )}
                            </Field>
                          </div>
                          <div>
                            <FieldArray name={`sections.${index}.chapters`}>
                              {({
                                push: pushChapter,
                                remove: removeChapter,
                              }) => (
                                <div>
                                  {section?.chapters.length > 0 &&
                                    section.chapters?.map(
                                      (chapter, chapterIndex) => (
                                        <div key={chapterIndex}>
                                          <Row>
                                            <Col md={12}>
                                              <Field
                                                name={`sections.${index}.chapters.${chapterIndex}.description`}
                                              >
                                                {({ field, form }) => (
                                                  <div className="mb-3">
                                                    <div className="d-flex justify-content-between">
                                                      <div className="mt-4">
                                                        <Label
                                                          htmlFor={`sections.${index}.chapters.${chapterIndex}.description`}
                                                          className=""
                                                        >
                                                          Chapter  {chapterIndex + 1} Details
                                                        </Label>
                                                      </div>

                                                      <div className="action-edit-delete my-4 d-flex">
                                                        <span
                                                          className="action-edit me-2"
                                                          onClick=""
                                                        >
                                                          <button
                                                            className="btn btn-soft-danger btn-rounded btn-sm"
                                                            type="button"
                                                            onClick={() =>
                                                              removeChapter(
                                                                chapterIndex
                                                              )
                                                            }
                                                          >
                                                            <DeleteCourseIcon/>
                                                          </button>
                                                          
                                                        </span>
                                                        <span className="action-delete">
                                                          <button
                                                            className="btn btn-soft-light btn-rounded btn-sm"
                                                            type="button"
                                                            onClick={() =>
                                                              pushChapter({
                                                                description: "",
                                                                video: "",
                                                              })
                                                            }
                                                          >
                                                            <AddCourseIcon color="#333"/>
                                                          </button>
                                                        </span>
                                                      </div>

                                                    </div>
                                                    
                                                    <Input
                                                      {...field}
                                                      name={`sections.${index}.chapters.${chapterIndex}.description`}
                                                      type="text"
                                                      placeholder={`Enter chapter ${chapterIndex + 1} description`}
                                                      onChange={
                                                        form.handleChange
                                                      }
                                                      onBlur={form.handleBlur}
                                                      // value={form.values.description || ""}
                                                      invalid={
                                                        form.touched
                                                          .description &&
                                                        form.errors.description
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                    {form.errors.sections &&
                                                      form.errors.sections[
                                                        index
                                                      ] &&
                                                      form.errors.sections[
                                                        index
                                                      ].chapters &&
                                                      form.sections[index]
                                                        .chapters[
                                                        chapterIndex
                                                      ] &&
                                                      form.errors.sections[
                                                        index
                                                      ].chapters[chapterIndex]
                                                        .description && (
                                                        <FormFeedback>
                                                          {
                                                            form.errors
                                                              .sections[index]
                                                              .chapters[
                                                              chapterIndex
                                                            ].description
                                                          }
                                                        </FormFeedback>
                                                      )}
                                                  </div>
                                                )}
                                              </Field>
                                            </Col>
                                            <Col md={12}>
                                              <Field
                                                name={`sections.${index}.chapters.${chapterIndex}.video`}
                                              >
                                                {({ field, form }) => (
                                                  <div className="mb-3">
                                                    {chapterLoading[index] &&
                                                    chapterLoading[index][
                                                      chapterIndex
                                                    ] ? (
                                                      <div className="d-block mt-3">
                                                        <BarLoader
                                                          color="green"
                                                          size={25}
                                                        />
                                                      </div>
                                                    ) : values.sections[index]
                                                        .chapters[chapterIndex]
                                                        .video === "" ? (
                                                          <div onClick={() => inputRef.current.click()}>
                                                          <span>
                                                           <input
                                                               {...field}
                                                               name={`sections.${index}.chapters.${chapterIndex}.video`}
                                                               type="file"
                                                               value={null}
                                                               id={`file_${index}_${chapterIndex}`}
                                                               onChange={(e) =>
                                                                 handleVideoUpload(
                                                                   e.target.files[0],
                                                                   index,
                                                                   chapterIndex
                                                                 )
                                                               }
                                                               ref={inputRef}
                                                               onBlur={form.handleBlur}
                                                               accept="video/mp4, video/webm, video/ogg"
                                                               className="upload-input"
                                                             />
                                                             <div className="upload-video d-flex justify-content-center">
                                                               <div className="">
                                                                 <UploadIcon2/>
                                                                 <span className="">Upload video</span>
                                                               </div>
                                                             </div>
                                                          </span>
                                                        </div>
                                                    ) : (
                                                      <div className="d-block mt-1">
                                                        <p
                                                          className=""
                                                          style={{
                                                            fontSize: "10px",
                                                          }}
                                                        >
                                                          {
                                                            values.sections[
                                                              index
                                                            ].chapters[
                                                              chapterIndex
                                                            ].video
                                                          }
                                                        </p>
                                                      </div>
                                                    )}

                                                    {form.errors.sections &&
                                                      form.errors.sections[
                                                        index
                                                      ] &&
                                                      form.errors.sections[
                                                        index
                                                      ].chapters &&
                                                      form.errors.sections[
                                                        index
                                                      ].chapters[
                                                        chapterIndex
                                                      ] &&
                                                      form.errors.sections[
                                                        index
                                                      ].chapters[chapterIndex]
                                                        .videoFile && (
                                                        <FormFeedback>
                                                          {
                                                            form.errors
                                                              .sections[index]
                                                              .chapters[
                                                              chapterIndex
                                                            ].videoFile
                                                          }
                                                        </FormFeedback>
                                                      )}
                                                  </div>
                                                )}
                                              </Field>
                                            </Col>
                                          </Row>
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            </FieldArray>
                          </div>
                          <button
                            className="btn btn-outline-danger btn-rounded waves-effect waves-light"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            <DeleteCourseIcon/>
                            <span className="mx-1">Remove section {index + 1}</span>
                          </button>
                        </CardBody>
                      </Card>
                      </Col>     
                    </Row>
                    </>
                  ))}
              </div>
            )}
          </FieldArray>
      </div>
    </React.Fragment>
  );
};

export default Step2;
