import React from "react";

export const StatSkeleton = () => {
  return (
    <div className="shadow-none mb-4" aria-hidden="true">
        <div>
            <h5 className="placeholder-glow">
                <span className="placeholder col-6"></span>
            </h5>
            <p className="placeholder-glow">
                <span className="placeholder col-7"></span>{" "}
                <span className="placeholder col-4"></span>{" "}
            </p>
        </div>
</div>
  );
};
