import React from 'react'
import ReactApexChart from "react-apexcharts"

const FindChart = (data) => {

    const series = [
        {
          name: "How you find us?",
          data: [data.data?.linkedInPercentage, data.data?.facebookPercentage, data.data?.twitterPercentage, data.data?.othersPercentage],
        },
      ]
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true, 
            dataLabels: {
              position: "top", // top, center, bottom
            },
            borderRadius: 20,
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "%"
          },
          offsetY: 0,
          offsetX: 300,
          style: {
            fontSize: "14px",
            colors: ["#333"],
            fontWeight: 600,
          },
        },
    
        colors: ["#D4E5FF"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: [
            "LinkedIn",
            "Facebook",
            "Twitter",
            "Others",
          ],
          position: "top",
          labels: {
            offsetY: -18,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
            offsetY: -35,
          },
        },
        fill: {
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            style: {
              fontSize: '14px', // Increase font size
              fontWeight: 600, // Increase font weight
              colors: ["#333"], // Specify label color
            },
          },
        },
    }

  return (
        <ReactApexChart options={options} series={series} type="bar" height={300} />
    )
}

export default FindChart
