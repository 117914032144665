import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages
import Course from "../pages/Course";
import PaidCourses from "../pages/PaidCourses";
import Settings from "../pages/Settings";
import MyCourses from "../pages/MyCourses";
import EnrolledCourses from "../pages/EnrolledCourses";
import CreateCourse from "../pages/CreateCourse";
import Submission from "../pages/Submission.js";
import ResetPassword from "../pages/Authentication/ResetPassword";
import EmailConfirmation from "../pages/Authentication/EmailConfirmation";
import UpdateCourse from "../pages/UpdateCourse";
import AddResources from "../pages/AddResources";

const userRoutes = [
  //dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/paid-courses", component: PaidCourses },
  { path: "/settings", component: Settings },
  { path: "/dashboard/:courseId", component: Course },
  {path: "/create-course", component: CreateCourse},
  {path: "/update-course/:id", component: UpdateCourse},
  {path: "/add-resources/:id", component: AddResources},
  {path: "/my-courses", component: MyCourses},
  {path: "/enrolled-students", component: EnrolledCourses},
  {path: "/submission/:studentId/:courseId", component: Submission},

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const authRoutes = [
  //landing page 
  // {path: "/certificate", component: Certificate},

  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/reset-password/:id", component: ResetPassword },
  { path: "/email-confirmation/:id", component: EmailConfirmation },


];

export { userRoutes, authRoutes };
