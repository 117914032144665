import React from 'react'

const Tool = () => {
  return (
    <div>
      <div>
          <h4 className="card-title mb-4">Tools recommended for this course</h4>
      </div>
      <dl className="row mb-0">
          <dt className="col-sm-3">Hardware</dt>
          <dd className="col-sm-9">A description list is perfect for defining terms.</dd>

          <dt className="col-sm-3">Software</dt>
          <dd className="col-sm-9">Vestibulum id ligula porta felis euismod semper eget lacinia odio sem nec elit.</dd>
          <dd className="col-sm-9 offset-sm-3">Donec id elit non mi porta gravida at eget metus.</dd>
        </dl>
    </div>
  )
}

export default Tool
