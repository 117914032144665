import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from "reactstrap";
import EnrolledList from './EnrolledList';
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from 'react-redux';
import debounce from "lodash.debounce";
import { coursesEnrolled, removeTitle, setTitle } from '../../store/course/reducer';
import TableSkeleton from '../../components/CommonForBoth/Stoched/TableSkeleton';
import Empty from '../../components/CommonForBoth/Stoched/Empty';
import OrdersBagImg from "../../assets/images/orderbags.svg"

const index = () => {
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState('')
    const [pageNumber, setPageNumber] = useState(0);
    const [enrols, setEnrols] = useState([]);

    const { enrolled, loading } = useSelector(state => ({
        enrolled: state.course.enrolled,
        loading: state.course.loading,
    }))

    // console.log('enrolled here', enrolled)

    //Pagination
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;

    const pageCount = Math.ceil(enrolled?.paginationDetails?.total / usersPerPage);

    const changePage = ({ selected }) => {
      setPageNumber(selected + 1);
    };

    useEffect(() => {
      dispatch(removeTitle())
      dispatch(setTitle(`Enrolled Students (${enrolled?.paginationDetails?.total})`))
    }, [])

    useEffect(() => {
        setEnrols(enrolled?.filteredStudentList)
    }, [enrolled])

    useEffect(() => {
        // Simulating the API call to fetch the data

        const query = {
            'pageNumber': pageNumber,
            'searchTerm': searchValue,
        }
        dispatch(coursesEnrolled(query))
    }, [pageNumber, searchValue]);

    // Debounced function to handle search submission
    const debouncedSearch = debounce((searchQuery) => {
        // Perform the actual search operation here
    }, 500); // Debounce delay set to 500 milliseconds

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchValue(value);

        // Trigger the debounced search function with the updated search value
        debouncedSearch(value);
    };

  return (
    <div className='page-content'>
       <Container fluid>
        <Row className="mt-3 mb-3">
          <Col xl={11} xs={12}>
              <div className='d-flex justify-content-between'>
                  {/* <div className='d-flex my-4'>
                      <h2 className=''>Enrolled Courses </h2>
                      <span className="text-muted fw-normal ms-2 mt-2">{enrolled?.paginationDetails?.total}</span>
                  </div> */}

                  <form className="app-search d-none d-lg-block mb-4">
                      <div className="position-relative">
                          <input type="text" className="form-control" placeholder="Search..." 
                              value={searchValue}
                              onChange={handleSearchChange}/>
                          <button className="btn btn-primary" type="button"><i className="bx bx-search-alt align-middle"></i></button>
                      </div>
                  </form>
              </div>

              {loading ? (
              <TableSkeleton />
            ) : enrolled?.filteredStudentList === undefined || enrolled?.filteredStudentList.length == 0 ? (
              <Empty
                img={OrdersBagImg}
                title="No New Order"
                msg="You do not have any new order at the moment"
              />

            ) : (
              <div className='me-3'><EnrolledList enrols={enrols}/></div>

            )}
          
          </Col>
            
           
        </Row>

          {/* Pagination */}
          <div className='mx-0 mt-4'>
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                />
            </div>

        
        </Container>
    </div>
  )
}

export default index
