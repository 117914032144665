import React from 'react'
import { NumericFormat } from 'react-number-format';

export const NumberFormatInput = ({ name, value, type, prefix, disabled, onChange }) => {
    return (
      <>
        <NumericFormat
          className="form-control input-design"
          thousandSeparator={false}
          name={name}
          type={type}
          prefix={prefix}
          onChange={onChange}
          placeholder="0.00"
          value={value}
          style={{
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8
          }}
          disabled={disabled}
          required />
      </>
    )
  }
