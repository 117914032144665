export const roundToSignificantFigures = (num) => {
    const sigFigs = 4;
    if (num === 0) return 0;
    const d = Math.ceil(Math.log10(Math.abs(num)));
    const power = sigFigs - d;
    const magnitude = Math.pow(10, power);
    const shifted = Math.round(num * magnitude);
    return shifted / magnitude;
};

export const roundToWholeNumber = (num) => {
    return Math.round(num); // You can use Math.floor or Math.ceil if needed
};