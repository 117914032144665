import { all, fork } from "redux-saga/effects"

//public

import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

import loginSaga from "./auths/login/saga"
import registerSaga from "./auths/register/saga"
import confirmEmailSaga from "./auths/emailConfirmation/saga"
import logOutSaga from "./auths/logout/saga"
import forgetPassSaga from "./auths/forgotPassword/saga"
import resetPassSaga from "./auths/resetPassword/saga"
import courseSaga from "./course/saga"
import profileSaga from "./auths/profile/saga"


export default function* rootSaga() {
  yield all([
    //public

    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(loginSaga),
    fork(registerSaga),
    fork(confirmEmailSaga),    
    fork(logOutSaga),
    fork(forgetPassSaga),
    fork(resetPassSaga),
    fork(courseSaga),
    fork(profileSaga)
  ])
}
