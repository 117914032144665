import React from 'react'
import { Card, CardBody } from "reactstrap";
import { truncateString } from '../../components/helpers/utils_functions';
import { AddCourseIcon, DeleteCourseIcon, EditCourseIcon } from '../../assets/icons/Custom';


const CourseCard = ({ course, onEdit, onDelete, onAdd }) => {

    function handleResource(){
        onAdd(course._id)
    }
    function handleEdit() {
        onEdit(course._id);
    }

    function handleDelete() {
        onDelete(course._id);
    }

  return (
    <Card className='card-courses'>
        <img className="card-img-top img-fluid" src={course.courseBanner} alt="" height={20}/>
        <CardBody className="overflow-hidden position-relative">  
            <h5 className="mb-0 course-title">{ truncateString(course.title, 20) }</h5>

            <div className='course-desc'>
                <p className="text-muted pb-5 mt-2">{ truncateString(course.description, 50)}</p>
            </div>

            <div className="faq-count d-flex justify-content-between mt-5">
                <h5 className="text-primary">${course.price}</h5>
                <div className=''>
                <span className='' onClick={handleResource}>
                    <AddCourseIcon color='#333'/>
                </span>
                <span className='mx-1' onClick={handleEdit}>
                    <EditCourseIcon/>
                </span>
                <span onClick={handleDelete}>
                    <DeleteCourseIcon/>
                </span>
            </div>
            </div>


            <div className='mt-2 mr-0 align-self-center'>
                <p className={`font-size-13 font-weight-bold my-0 ${course.isComplete? 'is-completed' : 'is-incompleted'}`}>{course.isComplete? "Completed" : "Incomplete"}</p>
            </div>
        </CardBody>
    </Card>
  )
}

export default CourseCard
