import { TOKEN, ENDPOINTS } from "../constants";
import axios from "axios";
import { store } from "../../store";
import baseUrl from "../../api";

const options = {
  headers: {
    Authorization: "Bearer " + TOKEN,
  },
};

// const baseUrl = process.env.REACT_APP_API_BASE;

//Add Course
export function createCourse(payload) {
  let url = `${baseUrl}` + ENDPOINTS.createCourse;
  return axios.post(url, payload, options);
}

//Upload Video
export function uploadVideo(formData) {
    let url = `${baseUrl}` + ENDPOINTS.uploadVideo;
    return axios.post(url, formData);
}

//Course Banner
export function courseBanner(formData, course_id){
  let url = `${baseUrl}` + ENDPOINTS.courseBanner + `${course_id}`;
  return axios.put(url, formData);
}

//Delete Course Banner
export function deleteBanner(course_id){
  console.log('delete course id', course_id)
  let url = `${baseUrl}` + ENDPOINTS.deleteBanner + `${course_id}`;
  return axios.put(url, {} , options);
}

//Upload Quiz
export function uploadQuiz(formData, course_id) {
  let url = `${baseUrl}` + ENDPOINTS.uploadQuiz + `${course_id}`
  return axios.post(url, formData);
}

//Delete Quiz
export function deleteQuiz(course_id){
  console.log('Delete quiz', course_id)
  let url = `${baseUrl}` + ENDPOINTS.deleteQuiz + `${course_id}`;
  return axios.delete(url, options);
}

//Upload Project
export function uploadProject(formData, course_id) {
  let url = `${baseUrl}` + ENDPOINTS.uploadProject + `${course_id}`
  return axios.put(url, formData);
}

//Delete Quiz
export function deleteProject(course_id){
  console.log('Delete project', course_id)
  let url = `${baseUrl}` + ENDPOINTS.deleteProject + `${course_id}`;
  return axios.delete(url, options);
}

//Course Price
export function coursePrice(values, course_id){
  console.log('check price', values)
  let url = `${baseUrl}` + ENDPOINTS.coursePrice + `${course_id}`;
  return axios.put(url, {'price': values.price}, options);
}

//Fetch Courses
export function fetchCourses(query) {
  let url = `${baseUrl}` + ENDPOINTS.fetchCourses + "?" + `page=${query.pageNumber}&search=${query.searchTerm}`;
  return axios.get(url, options);
}

//Fetch A Course
export function fetchCourse(course_id) {
  let url = `${baseUrl}` + ENDPOINTS.fetchCourse + `${course_id}`;
  return axios.get(url, options);
}

//Delete Course
export function deleteCourse(course_id){
  let url = `${baseUrl}` + ENDPOINTS.deleteCourse + `${course_id}`;
  return axios.put(url, {}, options);
}

//Update Course
export function updateCourse(values){

  const payload = {
    "title": values.title,
    "description": values.description,
    "sections": values.sections,
    "quizFile": values.quizFile,
    "projectFile": values.projectFile,
}

  let url = `${baseUrl}` + ENDPOINTS.updateCourse + `${values.courseId}`;
  return axios.put(url, payload, options);
}

//Get Students Enrolled Courses
export function enrolledCourses(query) {
  let url = `${baseUrl}` + ENDPOINTS.enrolledStudents + "?" + `page=${query.pageNumber}&search=${query.searchTerm}`;
  return axios.get(url, options);
}

//Upload Resource
export function resourceUpload(formData, payload){
  let url = `${baseUrl}` + ENDPOINTS.resourceUpload + `${payload.courseId}`;
  return axios.post(url, formData, options);
}

//Fetch Course Resources
export function fetchResources(query) {
  let url = `${baseUrl}` + ENDPOINTS.fetchResources + `${query.courseId}`;
  return axios.get(url, options);
}

//Delete Resource
export function deleteResource(payload){
  let url = `${baseUrl}` + ENDPOINTS.deleteResource + `${payload.courseId}` + "/" + `${payload.documentKey}`;
  return axios.delete(url);
}