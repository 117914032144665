import React from "react";
import { Col, Row, Label } from "reactstrap";
import { Field } from "formik";
import {NumberFormatInput} from "../../components/CommonForBoth/Stoched/Inputs";

const Step4 = (values) => {

  {console.log('Form Price', values)}

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={5}>
          <h3 className="page-title fs-heading font-weight-bold">
            Pricing
          </h3>
          <p className="mb-3 text-muted">
            Stoched collect this information to create your course contents.
          </p>
          <p className="mb-4 font-weight-bold">Step 4 of 4</p>

          <div>
            <Field name="title">
            {({ field, form }) => (
          
              <div className="mb-4">
                <Label className="form-label">Amount (USD)</Label>
                <NumberFormatInput {...field} name="amount" value={values.values.amount || ""} prefix="" onChange={form.handleChange}/>
              </div>
            )}
          </Field>
        </div> 
                    
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Step4;
