import React, { useState } from "react";
import { Col, Row, Input, Label, Form, FormFeedback, Card } from "reactstrap";
import { Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";

import { Image } from 'primereact/image';
import { deleteBanner, getCourse } from "../../store/course/reducer";
import { UploadIcon } from "../../assets/icons/Custom";
        

const Step1 = ({ setFieldValue }) => {


  const dispatch = useDispatch();
  const { loading } = useSelector(state => ({
    loading: state.course.isDeleting,
}))

  const [selectedFile, setselectedFile] = useState([]);

  function handleAcceptedFile(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFile(files);
  }

  // remove attachment
  function removeFile(f) {
    const newFiles = selectedFile.filter((file) => file !== f);
    setselectedFile(newFiles);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const icon = (<i className="pi pi-search"></i>)

  return (
    <React.Fragment>
      <Row>
        <Col md={5} sm={8} xs={12}>
          {/* <h2 className="mt-3">
            Let’s create your course
          </h2> */}
          <p className="mb-3 text-muted font-size-18">
            Provide your course title and description
          </p>
          <p className="mb-4 font-weight-bold">Step 1 of 4</p>


          <Field name="title">
            {({ field, form }) => (
              <div className="mb-3">
                <Label className="form-label">Course Title</Label>
                <Input
                  {...field}
                  name="title"
                  type="text"
                  placeholder="Enter your course title"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.title || ""}
                  invalid={
                    form.touched.title && form.errors.title
                      ? true
                      : false
                  }
                />
                <FormFeedback>{form.errors.title}</FormFeedback>
              </div>
            )}
          </Field>

          <Field name="description">
            {({ field, form }) => (
              <div className="mb-3">
                <Label className="form-label">Course Description </Label>
                <textarea
                  {...field}
                  name="description"
                  className="form-control"
                  rows="3"
                  placeholder="Enter your business description here"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.description || ""}
                />
                <FormFeedback>{form.errors.description}</FormFeedback>
              </div>
            )}
          </Field>

          <div>
            <Field name="banner">
              {({ field, form }) => (
                <div className="mb-3">
                 <Dropzone
                        onDrop={(acceptedFiles) => {
                        handleAcceptedFile(acceptedFiles);
                        setFieldValue('banner', acceptedFiles)
                        }}
                        accept="image/*"
                        maxFiles={1}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone mt-1">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-2">
                                <UploadIcon/>
                              </div>
                              <h6>Click to upload or drag and drop your Banner</h6>
                              <small className="text-muted fs-6 fw-light">
                                (Max. File Size 25MB)
                              </small>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    <div className="dropzone-previews my-4" id="file-previews">
                      {selectedFile.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  {f.type === "application/pdf" ? (
                                    <div className="avatar-sm">
                                      <div className="avatar-title rounded bg-light text-muted font-size-24">
                                        <i className="fas fa-file-pdf" />
                                      </div>
                                    </div>
                                  ) : (
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  )}
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col className="col-auto">
                                  <Link
                                    to="#"
                                    className="btn btn-link p-1"
                                    onClick={() => {
                                      removeFile(f);
                                    }}
                                  >
                                    <i className="dripicons-cross text-danger fs-5" />
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                </div>
              )}
            </Field>  
          </div>
          
          <div className="d-flex justify-content-between">
            <Field name="hasQuiz">
              {({ field, form }) => (
                <div className="mb-0">
                  <Label className="form-label me-2">Course Has Quiz </Label>
                  <Input
                  {...field}
                  type="checkbox"
                  name="hasQuiz"
                  defaultChecked={form.values.hasQuiz}
                  onChange={form.handleChange}
                  />
                </div>
              )}
            </Field>

            <Field name="hasProject">
            {({ field, form }) => (
              <div className="mb-0">
                <Label className="form-label me-2">Course Has Project </Label>
                <Input
                {...field}
                type="checkbox"
                name="hasProject"
                defaultChecked={form.values.hasProject}
                onChange={form.handleChange}
                />
              </div>
            )}
          </Field>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Step1;
