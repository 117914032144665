import React from 'react'

export const AddCourseIcon = ({color}) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3965 3.31885V16.4283" stroke={color} strokeWidth="1.22901" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.8418 9.87378H16.9512" stroke={color} strokeWidth="1.22901" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    )
}

export const EditCourseIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="19.6641" height="19.6641" transform="translate(0.290039 0.041748)" fill="white"/>
            <path d="M13.5749 4.12376L14.7233 2.97524C15.3577 2.34093 16.3861 2.34093 17.0204 2.97524C17.6547 3.60955 17.6547 4.63797 17.0204 5.27227L15.8719 6.42079M13.5749 4.12376L9.28652 8.4121C8.43009 9.26856 8.00185 9.69674 7.71025 10.2186C7.41865 10.7404 7.12527 11.9726 6.84473 13.1509C8.02301 12.8704 9.25522 12.577 9.77706 12.2854C10.2989 11.9938 10.7271 11.5656 11.5835 10.7091L15.8719 6.42079M13.5749 4.12376L15.8719 6.42079" stroke="#333333" strokeWidth="1.22901" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4961 9.87356C17.4961 13.3497 17.4961 15.0878 16.4163 16.1677C15.3364 17.2476 13.5982 17.2476 10.1221 17.2476C6.64593 17.2476 4.90786 17.2476 3.82795 16.1677C2.74805 15.0878 2.74805 13.3497 2.74805 9.87356C2.74805 6.3974 2.74805 4.65932 3.82795 3.57942C4.90786 2.49951 6.64593 2.49951 10.1221 2.49951" stroke="#333333" strokeWidth="1.22901" strokeLinecap="round"/>
        </svg>
    )
}

export const DeleteCourseIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9922 4.54761L15.4845 12.7616C15.3547 14.8601 15.2899 15.9095 14.7639 16.6639C14.5037 17.0369 14.169 17.3517 13.7806 17.5882C12.9952 18.0667 11.9439 18.0667 9.84121 18.0667C7.73585 18.0667 6.68315 18.0667 5.8972 17.5873C5.50863 17.3503 5.17368 17.035 4.91369 16.6614C4.38784 15.9058 4.32439 14.855 4.19753 12.7534L3.70215 4.54761" stroke="#CF3A3A" strokeWidth="1.22901" strokeLinecap="round"/>
            <path d="M2.47266 4.5481H17.2207M13.1697 4.5481L12.6104 3.39425C12.2388 2.62779 12.053 2.24455 11.7326 2.00554C11.6615 1.95252 11.5862 1.90536 11.5075 1.86453C11.1526 1.68042 10.7268 1.68042 9.87497 1.68042C9.0018 1.68042 8.56526 1.68042 8.20449 1.87224C8.12453 1.91476 8.04824 1.96383 7.97639 2.01895C7.65222 2.26764 7.47114 2.6649 7.10897 3.45942L6.61271 4.5481" stroke="#CF3A3A" strokeWidth="1.22901" strokeLinecap="round"/>
            <path d="M7.79883 13.5606V8.64453" stroke="#CF3A3A" strokeWidth="1.22901" strokeLinecap="round"/>
            <path d="M11.8945 13.5606V8.64453" stroke="#CF3A3A" strokeWidth="1.22901" strokeLinecap="round"/>
        </svg>
        
    )
}

export const UploadIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 21C9.20998 16.2487 13.9412 9.94746 21 14.6734" stroke="#0066F6" strokeWidth="1.5"/>
            <path d="M17 4.5C17.4915 3.9943 18.7998 2 19.5 2M19.5 2C20.2002 2 21.5085 3.9943 22 4.5M19.5 2V10" stroke="#0066F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.9999 13C20.998 17.147 20.9472 19.2703 19.6088 20.6088C18.2175 22 15.9783 22 11.5 22C7.02166 22 4.78249 22 3.39124 20.6088C2 19.2175 2 16.9783 2 12.5C2 8.02166 2 5.78249 3.39124 4.39124C4.78249 3 7.02166 3 11.5 3C11.6699 3 14 3.00008 14 3.00008" stroke="#0066F6" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export const UploadIcon2 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5.04004V15.04M12 5.04004C11.2998 5.04004 9.99153 7.03434 9.5 7.54004M12 5.04004C12.7002 5.04004 14.0085 7.03434 14.5 7.54004" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 19.04H19.0001" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

