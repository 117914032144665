import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Container, Input, Label, Form, FormFeedback  } from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useParams } from "react-router-dom"

// import images
import stoched from "../../assets/images/stoched_.png"

import CarouselPage from "../AuthenticationInner/CarouselPage"
import { passwordReset } from "../../store/auths/resetPassword/reducer";
import { PulseLoader } from 'react-spinners';

const ResetPassword = props => {
  const { id } = useParams();
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(false);

  
  //meta title
  document.title = "Reset Password | Stoched";

  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.resetPassword.isLoading,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validationSchema: Yup.object({
        password: Yup.string()
            .min(8, "Password must be 8 characters long")
            .matches(/[0-9]/, "Password requires a number")
            .matches(/[^\w]/, "Password requires a symbol")
            .required("Please Enter Your Password"),

        confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords Do not match"
        ),
    }),
    onSubmit: (values, {resetForm}) => {
      const payload = {
        token: id,
        newPassword: values.password
      }

      dispatch(passwordReset(payload))
      resetForm({values: ''})
    }
  });

  useEffect(() => {
    dispatch(apiError(""))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="overlay py-4">
          <div className="mb-4 mb-md-5 text-center py-4">
            <Link to="/dashboard" className="d-block auth-logo">
              <img src={stoched} alt="" height="28" /> <span className="logo-txt text-white">Stoched</span>
            </Link>
          </div>
        <Container fluid className="auth-page p-0">
          {/* <Row className="g-0"> */}
            {/* <CarouselPage /> */}
            {/* <Col lg={4} md={5} className="col-xxl-4"> */}
              <div className="d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reset Password</h5>
                        <p className="text-muted mt-2">Get your Stoched account now.</p>
                      </div>

                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        
                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <div className="input-group dashpass">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={showHidePassword ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                           {!validation.errors.password && (
                            <span
                                className="input-group-text"
                                onClick={() =>
                                setShowHidePassword(!showHidePassword)
                                }
                            >
                                {showHidePassword ? (
                                <i className="mdi text-muted fs-4 mdi-eye"></i>
                                ) : (
                                <i className="mdi text-muted fs-4 mdi-eye-off"></i>
                                )}
                            </span>
                            )}
                          </div>
                        </div>
                        
                        <div className="mb-3">
                          <Label className="form-label">Confirm Password</Label>
                          <div className="input-group dashpass">
                          <Input
                            name="confirmPassword"
                            type={showHideConfirmPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={
                              validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                            }
                          />
                          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                          ) : null}
                           {!validation.errors.confirmPassword && (
                            <span
                                className="input-group-text"
                                onClick={() =>
                                setShowHideConfirmPassword(!showHideConfirmPassword)
                                }
                            >
                                {showHideConfirmPassword ? (
                                <i className="mdi text-muted fs-4 mdi-eye"></i>
                                ) : (
                                <i className="mdi text-muted fs-4 mdi-eye-off"></i>
                                )}
                            </span>
                            )}
                            </div>
                        </div>
                        
                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            disabled={
                              !(validation.dirty && validation.isValid) ||
                              validation.isSubmitting
                            }
                          >
                            {loading ? <PulseLoader color="#fff" size={7} /> : "Reset Password"}

                          </button>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">Already have an account ? <Link to="/login"
                          className="text-primary fw-semibold"> Login </Link> </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </Col> */}
          {/* </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword