import React from 'react'
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";

const index = () => {

    const paid = [
        {
            'id': 'ST456778',
            'title': 'Introduction to Stoched',
            'description': 'New common language will be more simple and regular than the existing European languages. It will be as simple as occidental',
            'amount': "250.00",
            'url': img1

        },
        {
            'id': 'ST608773',
            'title': 'Design for Six Sigma',
            'description': 'New common language will be more simple and regular than the existing European languages. It will be as simple as occidental',
            'amount': "170.99",
            'url': img2
        },
        {
            'id': 'ST539705',
            'title': 'Sharing Six Sigma',
            'description': 'New common language will be more simple and regular than the existing European languages. It will be as simple as occidental',
            'amount': "550.00",
            'url': img3

        },
    ]

  return (
    <div className="page-content">
        <Container fluid>
            <Row className="mt-3">
                <h5 className='mb-3'>My Courses</h5>
                {paid.map((course, i) => 
                    <Col xl={4} sm={6} key={i}>
                        <Link to={`/dashboard/${course.id}`}>
                            <Card className='card-courses'>
                                <img className="card-img-top img-fluid" src={course.url} alt="" height={20}/>
                                <CardBody className="overflow-hidden position-relative">
                                    <div>
                                        {/* <i className="bx bx-help-circle widget-box-1-icon text-primary"></i> */}
                                        <span className='bx widget-box-1-icon'><p className=''>PAID</p></span>
                                    </div>
                                    <div className="faq-count">
                                        <h5 className="text-primary">${course.amount}</h5>
                                    </div>
                                    <h5 className="mt-3">{course.title}</h5>
                                    <p className="text-muted mt-3 mb-0">{course.description}</p>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                )}
            </Row>

        </Container>
      
    </div>
  )
}

export default index
