import React, {useState} from 'react'
import { Routes, Route, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row,  Nav,
    NavItem,
    NavLink, TabContent,
    TabPane, } from "reactstrap";
import classnames from "classnames";
import Note from './Note';
import Resources from './Resources';
import Quiz from './Quiz';
import Task from './Task';
import Tool from './Tool';
import Schedule from './Schedule';
import Content from './Content';
import Certificate from './Certificate';


const Course = () => {
  const [headerTab, setHeaderTab] = useState("1");
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

    let { courseId } = useParams();
  return (
    <div className="page-content">
        <Container fluid>
            <Row>
                <Col xs={9}>
                    <div className="card-deck-wrapper">
                        <div className="card-group">
                            <Card className="mb-4">
                                <CardBody>
                                    <h4 className="card-title mb-3">Flawless Launches</h4>
                                    <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                                        <iframe
                                        title="test1"
                                        className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className='mt-4'> 
                        <Nav tabs className="nav-tabs-custom nav-justified px-0">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: customActiveTab === "1",
                                    })}
                                    onClick={() => {
                                    toggleCustom("1");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Note</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: customActiveTab === "2",
                                    })}
                                    onClick={() => {
                                    toggleCustom("2");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Resources</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: customActiveTab === "3",
                                    })}
                                    onClick={() => {
                                    toggleCustom("3");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="far fa-envelope"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Quiz</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: customActiveTab === "4",
                                    })}
                                    onClick={() => {
                                    toggleCustom("4");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="fas fa-cog"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Task</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: customActiveTab === "5",
                                    })}
                                    onClick={() => {
                                    toggleCustom("5");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="fas fa-cog"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Tool</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: customActiveTab === "6",
                                    })}
                                    onClick={() => {
                                    toggleCustom("6");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="fas fa-cog"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Schedule</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                    active: customActiveTab === "7",
                                    })}
                                    onClick={() => {
                                    toggleCustom("7");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="fas fa-cog"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Certificate</span>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted px-0"
                        >
                            <TabPane tabId="1">
                            <Note/>
                            </TabPane>
                            <TabPane tabId="2">
                            <Resources/>
                            </TabPane>
                            <TabPane tabId="3">
                                <Quiz/>
                            </TabPane>
                            <TabPane tabId="4">
                                <Task/>
                            </TabPane>
                            <TabPane tabId="5">
                                <Tool/>
                            </TabPane>
                            <TabPane tabId="6">
                                <Schedule/>
                            </TabPane>
                            <TabPane tabId="7">
                                <Certificate/>
                            </TabPane>
                  </TabContent>
                    </div>
                </Col>
                <Col xs={3}>
                    <div className="card-deck-wrapper">
                        <div className="card-group">
                            <Card className="mb-4">
                                <CardBody className='px-0'>
                                    <h4 className="card-title px-3">Content</h4>
                                    <Content/>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Course
