import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import smallImage from "../../../assets/images/empty.jpeg";


export const Skeleton = () => {
  return (
    <Card className="shadow-none border mb-4" aria-hidden="true">
        <img src={smallImage} className="card-img-top" alt="..." height={120}/>
        <CardBody>
            <h5 className="card-title placeholder-glow">
                <span className="placeholder col-6"></span>
            </h5>
            <p className="card-text placeholder-glow">
                <span className="placeholder col-7"></span>{" "}
                <span className="placeholder col-4"></span>{" "}
                <span className="placeholder col-4"></span>{" "}
                <span className="placeholder col-6"></span>{" "}
                <span className="placeholder col-8"></span>{" "}
            </p>
        </CardBody>
</Card>
  );
};
