import { call, put, takeEvery } from "redux-saga/effects";
import { coursesEnrolledFailure, coursesEnrolledSuccess, createBannerFailure, createBannerSuccess, createCourseFailure, createCourseSuccess, deleteBannerFailure, deleteBannerSuccess, deleteCourseFailure, deleteCourseSuccess, deleteProjectFailure, deleteProjectSuccess, deleteQuizFailure, deleteQuizSuccess, deleteResourceFailure, deleteResourceSuccess, fetchCourseFailure, fetchCourseSuccess, getCourseFailure, getCourseSuccess, getCoursesFailure, getCoursesSuccess, getResourcesFailure, getResourcesSuccess, updateCourseFailure, updateCourseSuccess, updatePriceFailure, updatePriceSuccess, uploadProjectFailure, uploadProjectSuccess, uploadQuizFailure, uploadQuizSuccess, uploadResourceFailure, uploadResourceSuccess } from "./reducer";
import { toast } from "react-toastify";
import { createCourse, courseBanner, uploadQuiz, coursePrice, uploadProject, fetchCourses, deleteCourse, fetchCourse, updateCourse, enrolledCourses, resourceUpload, fetchResources, deleteResource, deleteBanner, deleteQuiz, deleteProject } from "../../api/requests/course";
import swal from 'sweetalert';


//Create Course
function* addCourse({ payload: values }) {
    const payload = {
        'title': values.title,
        'description': values.description,
        'sections': values.sections,
      }
    try {
        const response = yield call(createCourse, payload)

        toast.success(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          }) 

        yield put(createCourseSuccess(response.data.data._id));
        localStorage.setItem("course_id", response.data.data._id)

    } catch (error) {
      toast.error(error.response?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
      yield put(createCourseFailure(error));
    
    }
}

//Delete Course
function* removeCourse({ payload: values }) {
  try {
      const response = yield call(deleteCourse, values)
      toast.success(response.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

      yield put(deleteCourseSuccess(response.data.data));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(deleteCourseFailure(error));
  
  }
}

//Course Banner
function* addBanner({ payload: values }) {
  const course_id = localStorage.getItem("course_id")

  var formData = new FormData();
  formData.append('image', values.banner[0])

  try {
      const response = yield call(courseBanner, formData, course_id)

      toast.success(response.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

      yield put(createBannerSuccess(response.data.data._id));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(createBannerFailure(error));
  
  }
}

//Delete Banner
function* removeBanner({ payload: values }) {
  try {
      const response = yield call(deleteBanner, values)
      toast.success(response.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

      yield put(deleteBannerSuccess(response.data.data));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(deleteBannerFailure(error));
  
  }
}

//Upload Quiz
function* addQuiz({ payload: values }) {
  const course_id = localStorage.getItem("course_id")
  var formData = new FormData();
  formData.append('spreadsheet', values.quizFile[0])

  try {
      const response = yield call(uploadQuiz, formData, course_id )
      toast.success(response.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

      yield put(uploadQuizSuccess(response.data.data));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(uploadQuizFailure(error));
  
  }
}

//Delete Quiz
function* removeQuiz({ payload: values }) {
  try {
      const response = yield call(deleteQuiz, values)

      toast.success(response.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

      yield put(deleteQuizSuccess(response.data.message));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(deleteQuizFailure(error));
  
  }
}

//Upload Project
function* addProject({ payload: values }) {
  const course_id = localStorage.getItem("course_id")
  var formData = new FormData();
  formData.append('spreadsheet', values.projectFile[0])

  try {
      const response = yield call(uploadProject, formData, course_id )
      toast.success(response.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

      yield put(uploadProjectSuccess(response.data.data));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(uploadProjectFailure(error));
  
  }
}

//Delete Project
function* removeProject({ payload: values }) {
  try {
      const response = yield call(deleteProject, values)

      toast.success(response.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

      yield put(deleteProjectSuccess(response.data.message));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(deleteProjectFailure(error));
  
  }
}

//Update Price
function* addPrice({ payload: values }) {
  const course_id = localStorage.getItem("course_id")
  console.log('check price', values.amount)
  try {
      const response = yield call(coursePrice, {
        'price': parseInt(values.amount)
      }, course_id)

      toast.success(response.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

      yield put(updatePriceSuccess(response.data.data));

      swal("Bravo!", "All Steps Completed!", "success");
        
      setTimeout(() => {
        window.location.href = "/my-courses"
      }, 2000)

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(updatePriceFailure(error));
  
  }
}

//Fetch Courses
function* fetchAllCourses({ payload: query }) {
  try {
      const response = yield call(fetchCourses, query)

      console.log('All courses', response)

      yield put(getCoursesSuccess(response.data.data));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(getCoursesFailure(error));
  
  }
}

//Fetch A Course
function* fetchOneCourse({ payload: query }) {
  try {
      const response = yield call(fetchCourse, query)

      console.log("Alike is here", response)
      yield put(getCourseSuccess(response.data.data));

  } catch (error) {
  //   toast.error(error.response?.data.message, {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored",
  // });
    yield put(getCourseFailure(error));
  
  }
}

//Update A Course
function* editCourse({ payload: query }) {

  try { 
    const response = yield call(updateCourse, query)
    toast.success(response.data?.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      }) 
      
    yield put(updateCourseSuccess(response.data.data));
    localStorage.setItem("course_id", response.data.data._id)

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(updateCourseFailure(error));
  
  }
}

  //Students Enrolled Courses

  function* studentEnrolledCourses({ payload: query }) {
    try {
        const response = yield call(enrolledCourses, query)

        console.log('Enrolled Students', response)

        yield put(coursesEnrolledSuccess(response.data.data));
    } catch (error) {
      toast.error(error.response?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
      yield put(coursesEnrolledFailure(error));
    
    }
  }

  //Upload Resources
  function* addResource({ payload: values}) {
    let payload = {
      'courseId': values.courseId
    }

    var formData = new FormData();
    formData.append('file', values.files[0])

    try {
        const response = yield call(resourceUpload, formData, payload)
        toast.success(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }) 
        yield put(uploadResourceSuccess(response.data.message));

    } catch (error) {
      toast.error(error.response?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
      yield put(uploadResourceFailure(error));
    }
  }

//Fetch Resources
  function* fetchAllResources({ payload: query }) {

    try {
      const response = yield call(fetchResources, query)
      console.log('Fetched resources', response)

      yield put(getResourcesSuccess(response.data.data));
    } catch (error) {
      toast.error(error.response?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
      yield put(getResourcesFailure(error));
    
    }
  }

  //Delete Resource
  function* removeResource({ payload: values }) {
    try {
        const response = yield call(deleteResource, values)

        toast.success(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          }) 

        yield put(deleteResourceSuccess(response.data.message));

    } catch (error) {
      console.log('ERROR', error)
      yield put(deleteResourceFailure(error));
    
    }
  }
  function* courseSaga(){
    yield takeEvery('course/createCourse', addCourse)
    yield takeEvery('course/deleteCourse', removeCourse)
    yield takeEvery('course/createBanner', addBanner)
    yield takeEvery('course/deleteBanner', removeBanner)
    yield takeEvery('course/uploadQuiz', addQuiz)
    yield takeEvery('course/deleteQuiz', removeQuiz)
    yield takeEvery('course/uploadProject', addProject)
    yield takeEvery('course/deleteProject', removeProject)
    yield takeEvery('course/updatePrice', addPrice)
    yield takeEvery('course/getCourses', fetchAllCourses)
    yield takeEvery('course/getCourse', fetchOneCourse)
    yield takeEvery('course/updateCourse', editCourse)
    yield takeEvery('course/coursesEnrolled', studentEnrolledCourses)
    yield takeEvery('course/uploadResource', addResource)
    yield takeEvery('course/getResources', fetchAllResources)
    yield takeEvery('course/deleteResource', removeResource)
  }

  export default courseSaga