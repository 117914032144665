// import { createStore, applyMiddleware, compose } from "redux"
import { configureStore, getDefaultMiddleware  } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer } from "redux-persist";

import rootReducer from "./reducers"
import rootSaga from "./sagas"

import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ['login', 'logout', 'course']
};

const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer ,
  middleware: [...getDefaultMiddleware({
    serializableCheck: false,
  }), sagaMiddleware]
})
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store);

