import React from 'react';
import Skeleton from 'react-loading-skeleton'
import { Row, Col } from "reactstrap";
import 'react-loading-skeleton/dist/skeleton.css'

const TableSkeleton = ({count, rows}) => {
    const rowNums = [1, 2, 4, 4, 5, 6, 7, 8, 9];
    return(
        <div className="table-skeleton">
            { rowNums.map((row, i) => 
                <div className='table-header mb-2' key={i}>
                <Row>
                    {/* <Col className="col-1"><Skeleton circle width={30} height={30} /></Col> */}
                    <Col className="col-3"><Skeleton height={35}/> </Col>
                    <Col className="col-3"><Skeleton height={35}/></Col>
                    <Col className="col-3"><Skeleton height={35}/></Col>
                    <Col className="col-3"><Skeleton height={35}/></Col>
                </Row>
            </div>
            )}
        </div>
    );
}

export default TableSkeleton;