export const TOKEN = localStorage.getItem("token");

export const ENDPOINTS = {
    //Authentication
    login: "author/login",
    register: 'registration',
    confirmEmail: 'account/confirmation/',
    logout: 'logout',
    forgotPassword: 'forgotPassword',
    resetPassword: 'password/setnew/',
    changePassword: 'reset/',
    updateProfile: 'account/edit/',
    loggedInUser: '',
    refreshToken: "generate-token",

    //Course
    createCourse: 'course/create',
    uploadVideo: 'api/upload',
    courseBanner: 'course/banner/',
    deleteBanner: 'course/banner/delete/',
    uploadQuiz: 'api/upload-excel/',
    deleteQuiz: 'course/quiz/delete/',
    uploadProject: 'course/project/',
    deleteProject: 'project/delete/',
    coursePrice: 'course/price/',
    fetchCourses: 'courses/paginated',
    deleteCourse: 'course/delete/',
    fetchCourse: 'author/course/',
    updateCourse: 'course/edit/',
    updatePicture: 'update/profile-picture/',
    enrolledStudents: 'author/students',
    homeStats: 'author/course-metrics',
    downloadProject: 'project/download/',
    resourceUpload: 'api/course/docs/upload/',
    fetchResources: 'course/doc/',
    downloadResource: 'api/course/doc/download/',
    deleteResource: 'course/docs/delete/',
  };
  
