import React, {useState} from 'react'
import Dropzone from "react-dropzone";
import { Field } from "formik";
import { Col, Row, Input, Label, Card, FormFeedback, Button } from "reactstrap";
import { Link } from "react-router-dom";
import downloadIcon from "../../assets/icons/stoched/download.svg"


const Task = () => {
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
   function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // remove attachment
  function removeFile(f) {
    const newFiles = selectedFiles.filter((file) => file !== f);
    setselectedFiles(newFiles);
  }

  return (
    <div>
      <div className="mb-4 d-flex justify-content-between">
         <div>
            <h5>Instruction</h5>
            <p className="pe-4 font-size-15">After completing my program, I plan on working in industry or research as a professor to continue collaborating with other individuals in this field as well as mentoring students.</p>
         </div>
         <div>
            <Button
              color="outline-light"
              className="bg-transparent d-flex align-items-center mt-3"
            >
              <img src={downloadIcon} alt="" className="me-2" />
              Download
            </Button>
         </div>
      </div>
      <Row>
            <Col>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                  setFieldValue('files', acceptedFiles)
                }}
                accept="image/*, application/pdf"
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone mt-1">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-0">
                        <i className="display-5 text-muted bx bx-cloud-upload" />
                      </div>
                      <h5>Drop your document, or click to browse.</h5>
                      <small className="text-muted fs-6 fw-light">
                        1600 x 1200 (4:3) recommended, up to 10MB each.
                      </small>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews my-5" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            {f.type === "application/pdf" ? (
                              <div className="avatar-sm">
                                <div className="avatar-title rounded bg-light text-muted font-size-24">
                                  <i className="fas fa-file-pdf" />
                                </div>
                              </div>
                            ) : (
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            )}
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted document-upload-title"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0 font-size-12">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                          {/* delete icon */}
                          <Col className="col-auto">
                            <Link
                              to="#"
                              className="btn btn-link p-1"
                              onClick={() => {
                                removeFile(f);
                              }}
                            >
                              <i className="dripicons-cross text-danger fs-5" />
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Col>
          </Row>
          <div className="mb-4 d-flex justify-content-end">
              <button
                className="btn btn-lg btn-primary px-4"
                type="submit"
              >
                Submit Task
              </button>
            </div>
          </div>
  )
}

export default Task
