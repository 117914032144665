import React from 'react'
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import resource1 from "../../assets/images/resource_1.jpg"
import resource2 from "../../assets/images/resource_2.jpeg"
import resource3 from "../../assets/images/resource_3.jpeg"
import resource4 from "../../assets/images/resource_4.jpg"

const Resources = () => {
  return (
    <div className='my-4'>
        <Row>
            <Col md={6} xl={3}>

                <Card>
                    <img className="card-img-top img-fluid" src={resource1} alt="" />
                    <CardBody>
                        <p className="card-text">Some quick example text to build on the card title and make
                            up the bulk of the card's content.</p>
                        <Link to="#" className="card-link">Amazon</Link>
                    </CardBody>
                </Card>

            </Col>

            <Col md={6} xl={3}>

                <Card>
                    <img className="card-img-top img-fluid" src={resource2} alt="" />
                    <CardBody>
                        <p className="card-text">Some quick example text to build on the card title and make
                            up the bulk of the card's content.</p>
                        <Link to="#" className="card-link">Amazon</Link>
                    </CardBody>
                </Card>

            </Col>

            <Col md={6} xl={3}>

                <Card>
                    <img className="card-img-top img-fluid" src={resource3} alt="" />
                    <CardBody>
                        <p className="card-text">Some quick example text to build on the card title and make
                            up the bulk of the card's content.</p>
                        <Link to="#" className="card-link">Amazon</Link>
                    </CardBody>
                </Card>

            </Col>


            <Col md={6} xl={3}>

                <Card>
                    <img className="img-fluid" src={resource4} alt="" />
                    <CardBody>
                        <p className="card-text">Some quick example text to build on the card title and make
                            up the bulk of the card's content.</p>
                        <Link to="#" className="card-link">Amazon</Link>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export default Resources
