import React, { useState } from "react";
import {
  Collapse,

} from "reactstrap";
import classnames from "classnames";
import { VideoIcon } from "../../assets/icons/Custom";
import { MdVideoLibrary } from 'react-icons/md';


const Content = () => {
    const [col9, setcol9] = useState(true);
    const [col10, setcol10] = useState(false);
    const [col11, setcol11] = useState(false);

    const t_col9 = () => {
        setcol9(!col9);
        setcol10(false);
        setcol11(false);
      };
    
      const t_col10 = () => {
        setcol10(!col10);
        setcol9(false);
        setcol11(false);
      };
    
      const t_col11 = () => {
        setcol11(!col11);
        setcol10(false);
        setcol9(false);
      };

  return (
    <div>
      <div
        className="accordion accordion-flush "
        id="accordionFlushExample"
        >
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingFlushOne">
            <button
                className={classnames(
                "accordion-button",
                "fw-medium",
                { collapsed: !col9 }
                )}
                type="button"
                onClick={t_col9}
                style={{ cursor: "pointer" }}
            >
                Section 1: Intro
            </button>
            </h2>

            <Collapse isOpen={col9} className="accordion-collapse">
            <div className="accordion-body">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck1"
                      />
                      <label className="form-check-label" htmlFor="formCheck1">
                        <span className="d-block">1. Introduction to Stoched</span>
                        <span className="d-flex">
                            <span className="me-2"><MdVideoLibrary /></span>
                            <p className="card-text"><small className="text-muted">15 mins</small></p>
                        </span>
                      </label>
                    </div>
            </div>
            </Collapse>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingFlushTwo">
            <button
                className={classnames(
                "accordion-button",
                "fw-medium",
                { collapsed: !col10 }
                )}
                type="button"
                onClick={t_col10}
                style={{ cursor: "pointer" }}
            >
                Section 2: Requirements
            </button>
            </h2>

            <Collapse isOpen={col10} className="accordion-collapse">
            <div className="accordion-body">
                <div className="form-check mb-3">
                    <input
                    className="form-check-input"
                    type="checkbox"
                    id="formCheck1"
                    />
                    <label className="form-check-label" htmlFor="formCheck1">
                        <span className="d-block"> 2. Functional Model</span>
                        <span className="d-flex">
                                <span className="me-2"><MdVideoLibrary /></span>
                                <p className="card-text"><small className="text-muted">20 mins</small></p>
                        </span>
                    </label>
                </div>
                <div className="form-check mb-3">
                    <input
                    className="form-check-input"
                    type="checkbox"
                    id="formCheck1"
                    />
                    <label className="form-check-label" htmlFor="formCheck1">
                    
                        <span className="d-block"> 3. Voice of Customer</span>
                        <span className="d-flex">
                            <span className="me-2"><MdVideoLibrary /></span>
                            <p className="card-text"><small className="text-muted">25 mins</small></p>
                        </span>
                    </label>
                </div>
                <div className="form-check mb-3">
                    <input
                    className="form-check-input"
                    type="checkbox"
                    id="formCheck1"
                    />
                    <label className="form-check-label" htmlFor="formCheck1">
                    <span className="d-block">4. House of Quality</span>
                        <span className="d-flex">
                            <span className="me-2"><MdVideoLibrary /></span>
                            <p className="card-text"><small className="text-muted">30 mins</small></p>
                        </span>
                    </label>
                </div>
            </div>
            </Collapse>
        </div>
        </div>
    </div>
  )
}

export default Content
