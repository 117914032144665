import React from 'react'

export const BalanceIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.333 34.1668H11.6663C6.66634 34.1668 3.33301 31.6668 3.33301 25.8335V14.1668C3.33301 8.3335 6.66634 5.8335 11.6663 5.8335H28.333C33.333 5.8335 36.6663 8.3335 36.6663 14.1668V25.8335C36.6663 31.6668 33.333 34.1668 28.333 34.1668Z" stroke="#3AA05E" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.16699 15.8333V24.1667M30.8337 15.8333V24.1667M20.0003 25C21.3264 25 22.5982 24.4732 23.5359 23.5355C24.4735 22.5979 25.0003 21.3261 25.0003 20C25.0003 18.6739 24.4735 17.4021 23.5359 16.4645C22.5982 15.5268 21.3264 15 20.0003 15C18.6742 15 17.4025 15.5268 16.4648 16.4645C15.5271 17.4021 15.0003 18.6739 15.0003 20C15.0003 21.3261 15.5271 22.5979 16.4648 23.5355C17.4025 24.4732 18.6742 25 20.0003 25V25Z" stroke="#3AA05E" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const CourseIcon = () => {
    return (

        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 9.75V32.5" stroke="#0066F6" strokeWidth="2.4375" strokeLinecap="round"/>
            <path d="M9.71841 5.33884C15.1478 6.3726 18.3838 8.53501 19.5 9.77646C20.6162 8.53501 23.8521 6.3726 29.2815 5.33884C32.0322 4.81512 33.4074 4.55327 34.5787 5.55692C35.75 6.56057 35.75 8.19036 35.75 11.4499V23.1644C35.75 26.1448 35.75 27.6349 34.9983 28.5654C34.2465 29.4957 32.5916 29.8108 29.2815 30.4411C26.331 31.0029 24.0282 31.8979 22.3613 32.7975C20.7214 33.6825 19.9014 34.125 19.5 34.125C19.0986 34.125 18.2786 33.6825 16.6387 32.7975C14.9718 31.8979 12.669 31.0029 9.71841 30.4411C6.40843 29.8108 4.75345 29.4957 4.00172 28.5654C3.25 27.6349 3.25 26.1448 3.25 23.1644V11.4499C3.25 8.19036 3.25 6.56057 4.42127 5.55692C5.59255 4.55327 6.96784 4.81512 9.71841 5.33884Z" stroke="#0066F6" strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export const StudentIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.6663 16.6667C28.3247 15.6136 24.3133 15 19.9997 15C15.686 15 11.6747 15.6136 8.33301 16.6667V22.5C11.6747 21.447 15.686 20.8333 19.9997 20.8333C24.3133 20.8333 28.3247 21.447 31.6663 22.5V16.6667Z" stroke="#9347F3" strokeWidth="2.5" strokeLinejoin="round"/>
            <path d="M31.6663 21.667V25.039C31.6663 28.5907 29.9462 31.882 27.135 33.7093L24.8017 35.226C21.846 37.1473 18.1533 37.1473 15.1977 35.226L12.8644 33.7093C10.0531 31.882 8.33301 28.5907 8.33301 25.039V21.667" stroke="#9347F3" strokeWidth="2.5" strokeLinecap="round"/>
            <path d="M31.6663 16.6663L33.5425 15.6782C35.6477 14.2976 36.7002 13.6073 36.6655 12.621C36.6308 11.6348 35.533 11.0347 33.3372 9.83469L25.4585 5.52894C22.7798 4.06499 21.4405 3.33301 19.9997 3.33301C18.5588 3.33301 17.2195 4.06499 14.5408 5.52894L6.66222 9.83469C4.4664 11.0347 3.36849 11.6348 3.33384 12.621C3.29917 13.6073 4.35175 14.2976 6.4569 15.6782L8.333 16.6663" stroke="#9347F3" strokeWidth="2.5" strokeLinecap="round"/>
        </svg>
    )
}

export const CertificateIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.1667 36.6663C12.4884 36.6663 9.14932 36.6663 7.07467 34.2255C5 31.7848 5 27.8563 5 19.9997C5 12.1429 5 8.21456 7.07467 5.77379C9.14932 3.33301 12.4884 3.33301 19.1667 3.33301C25.8448 3.33301 29.184 3.33301 31.2587 5.77379C32.9287 7.73854 33.2545 10.6672 33.318 15.833" stroke="#F4841C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.333 13.333H24.9997M13.333 21.6663H18.333" stroke="#F4841C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32.6817 30.1757C34.0865 29.153 34.9997 27.4957 34.9997 25.625C34.9997 22.5183 32.4813 20 29.3747 20H28.958C25.8513 20 23.333 22.5183 23.333 25.625C23.333 27.4957 24.2462 29.153 25.651 30.1757M32.6817 30.1757C31.7535 30.8513 30.6107 31.25 29.3747 31.25H28.958C27.722 31.25 26.5792 30.8513 25.651 30.1757M32.6817 30.1757L33.653 33.234C34.0235 34.4005 34.2088 34.9838 34.1582 35.347C34.0527 36.1028 33.4362 36.664 32.7083 36.6667C32.3587 36.668 31.8347 36.393 30.7868 35.8432C30.3375 35.6073 30.1128 35.4893 29.883 35.42C29.4147 35.2788 28.918 35.2788 28.4497 35.42C28.2198 35.4893 27.9952 35.6073 27.5458 35.8432C26.498 36.393 25.974 36.668 25.6243 36.6667C24.8965 36.664 24.28 36.1028 24.1745 35.347C24.1238 34.9838 24.3092 34.4005 24.6797 33.234L25.651 30.1757" stroke="#F4841C" strokeWidth="2.5"/>
        </svg>
        
    )
}

export const ProfessionalIcon = () => {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.999" cy="11.4995" r="11" fill="#E06262"/>
        </svg> 
    )
}

export const StudIcon = () => {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.999" cy="11.4995" r="11" fill="#65A36F"/>
        </svg>
    )
}

export const OtherIcon = () => {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.999" cy="11.4995" r="11" fill="#47ADD9"/>
        </svg>       
    )
}