import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Alert, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { PulseLoader } from 'react-spinners';

// import images
import stoched from "../../assets/images/stoched_.png"
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { passwordForget } from "../../store/auths/forgotPassword/reducer";

const ForgetPasswordPage = (history) => {

  //meta title
  document.title = "Forget Password | Stoched";

  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.forgotPassword.isLoading,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values, {resetForm}) => {
      dispatch(passwordForget(values))
      resetForm({values: ''})
    }
  });

  return (
    <React.Fragment>
      <div className="overlay">
        <div className="d-flex justify-content-center pt-5">
            <Link to="/dashboard" className="d-block auth-logo py-3">
              <img src={stoched} alt="" height="35" /> <span className="logo-txt text-white">Stoched</span>
            </Link>
          </div>
        <Container fluid className="auth-page p-0 my-5">
              <div className="d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-md-2">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <span className="logo-txt">Forgot Password</span>
                      </Link>
                    </div>
                    <div className="auth-content">
                      <div className="">
                          <p className="text-muted mb-0">Remember it ? <Link to="/login"
                          className="text-primary fw-semibold"> Log in </Link> </p>
                      </div>

                      <Form
                        className="custom-form mt-4"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">

                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}

                        </div>

                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              {loading ? <PulseLoader color="#fff" size={7} /> : "Reset"}

                            </button>
                          </Col>
                        </Row>

                      </Form>
                    </div>
                  </div>
                </div>
              </div>

        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default ForgetPasswordPage;