import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Input, Label, Form, Card, FormFeedback } from "reactstrap";
import { Field } from "formik";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { deleteProject, deleteQuiz, getCourse } from "../../store/course/reducer";
import { PulseLoader } from 'react-spinners';
import { UploadIcon } from "../../assets/icons/Custom";


const Step3 = ({ setFieldValue, values, data }) => {

    const dispatch = useDispatch()

    const { response, delProj, delQuiz } = useSelector(state => ({
        response: state.course.deleteResponse,
        delProj: state.course.isDeletingProject,
        delQuiz: state.course.isDeletingQuiz,
    }))

  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedProject, setselectedProject] = useState([]);

  const [sections, setSections] = useState([
    { title: "Section 1", contents: [{ description: "", video: "" }] },
  ]);


  console.log('Delete response', response)

  useEffect(() => {
    dispatch(getCourse(data._id))
  }, [response])

  const addSection = () => {
    const newSection = { title: `Section ${sections.length + 1}`, contents: [{ description: "", video: "" }] };
    setSections([...sections, newSection]);
  };

  const addContent = (sectionIndex) => {
    const newContent = { description: "", video: "" };
    const updatedSections = [...sections];
    updatedSections[sectionIndex].contents.push(newContent);
    setSections(updatedSections);
  };

  const handleSectionChange = (event, sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].title = event.target.value;
    setSections(updatedSections);
  };

  const handleContentChange = (event, sectionIndex, contentIndex, key) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].contents[contentIndex][key] = event.target.value;
    setSections(updatedSections);
  };



  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  // remove attachment
  function removeFile(f) {
    const newFiles = selectedFiles.filter((file) => file !== f);
    setselectedFiles(newFiles);
  }

  function handleAcceptedProject(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedProject(files);
  }

  // remove attachment
  function removeProject(f) {
    const newFiles = selectedProject.filter((file) => file !== f);
    setselectedProject(newFiles);
  }
  

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  
    function handleDeleteQuiz(){
        dispatch(deleteQuiz(data._id))
    }

    function handleDeleteProject(){
        dispatch(deleteProject(data._id))
    }
  return (
    <React.Fragment>
      <div>
        <h3 className="page-title fs-heading font-weight-bold">
            Quiz & Project
          </h3>
          <p className="mb-3 text-muted">
            Stoched collect this information to create your course contents.
          </p>
          <p className="mb-4 font-weight-bold">Step 3 of 4</p>


          <Row>
            <Col xs={12} md={3}>
              { data.hasQuiz ? 
                 
                      <Card className="border shadow-none">
                      <img
                          className="card-img-top img-fluid"
                          src={`https://s3.amazonaws.com/staging-author.stoched.com/quiz.jpeg`}
                          alt="Samply"
                      />
                      <div className="py-2 px-2">
                          <button
                            type="button"
                            className="btn btn-outline-danger waves-effect waves-light my-2"
                            onClick={handleDeleteQuiz}
                            disabled={delQuiz}
                          >
                            {delQuiz? <PulseLoader color="red" size={7} /> :  'Delete Quiz' }
                          </button>
                      </div>
                      </Card>
              
              : ( !data.hasQuiz ? 
                  
                  <>

                  <Dropzone
                      onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                      setFieldValue('quizFile', acceptedFiles)
                      }}
                      accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      maxFiles={1}
                  >
                      {({ getRootProps, getInputProps }) => (
                      <div className="dropzone mt-3">
                          <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                          >
                          <input {...getInputProps()} />
                          <div className="mb-1">
                            <UploadIcon/>
                          </div>
                          <h6>Select Quiz or drop it</h6>
                            <small className="text-muted fs-6 fw-light">
                            (Max. File Size 25MB)
                            </small>
                          </div>
                      </div>
                      )}
                  </Dropzone>
                  <div className="dropzone-previews my-4" id="file-previews">
                      {selectedFiles.map((f, i) => {
                      return (
                          <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                          >
                          <div className="p-2">
                              <Row className="align-items-center">
                              <Col>
                                  <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                  >
                                  {f.name}
                                  </Link>
                                  <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                  </p>
                              </Col>
                              <Col className="col-auto">
                                  <Link
                                  to="#"
                                  className="btn btn-link p-1"
                                  onClick={() => {
                                      removeFile(f);
                                  }}
                                  >
                                  <i className="dripicons-cross text-danger fs-5" />
                                  </Link>
                              </Col>
                              </Row>
                          </div>
                          </Card>
                      );
                      })}
                  </div>
                  </>
                  
                  : <>No Quiz for this course</>)}
                
            </Col>

              <Col  xs={12} md={3}>

                  {data.hasProject ? 
                    
                            <Card className="border shadow-none">
                            <img
                                className="card-img-top img-fluid"
                                src={`https://s3.amazonaws.com/staging-author.stoched.com/project.jpeg`}
                                alt="Samply"
                            />
                            <div className="py-2 px-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-danger waves-effect waves-light my-2"
                                    onClick={handleDeleteProject}
                                    disabled={delProj}
                                >
                                  {delProj? <PulseLoader color="red" size={7} /> :  'Delete Project' }

                                </button>
                            </div>
                            </Card>
                     
                    : ( !data.hasProject?  
                        <>

                                <Dropzone
                                    onDrop={(acceptedFiles) => {
                                    handleAcceptedProject(acceptedFiles);
                                    setFieldValue('projectFile', acceptedFiles)
                                    }}
                                    accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    maxFiles={1}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone mt-3">
                                        <div
                                        className="dz-message needsclick mt-2"
                                        {...getRootProps()}
                                        >
                                        <input {...getInputProps()} />
                                        <div className="mb-1">
                                          <UploadIcon/>
                                        </div>
                                        <h6>Select Project or drop it</h6>
                                          <small className="text-muted fs-6 fw-light">
                                          (Max. File Size 25MB)
                                          </small>
                                        </div>
                                    </div>
                                    )}
                                </Dropzone>
                                <div className="dropzone-previews my-4" id="file-previews">
                                    {selectedProject.map((f, i) => {
                                    return (
                                        <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                        >
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                            <Col>
                                                <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                                >
                                                {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                                </p>
                                            </Col>
                                            <Col className="col-auto">
                                                <Link
                                                to="#"
                                                className="btn btn-link p-1"
                                                onClick={() => {
                                                    removeProject(f);
                                                }}
                                                >
                                                <i className="dripicons-cross text-danger fs-5" />
                                                </Link>
                                            </Col>
                                            </Row>
                                        </div>
                                        </Card>
                                    );
                                    })}
                                </div>
                    </> : <>No Quiz for this course</>
                  )}
              </Col>
            </Row>
      </div>
    </React.Fragment>
  );
};

export default Step3;
